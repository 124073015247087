import BaseElement from '../base/base-element';

class Draggable extends BaseElement {
  constructor() {
    super();
    this.setAttribute('draggable', true);
    this.classList.add('pin-draggable');
  }
}

if (!window.customElements.get('pin-draggable')) {
  window.customElements.define('pin-draggable', Draggable);
}
