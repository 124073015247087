export default (transport) => {
    return {
        markIncorrectGuesses: async (participantId, guesses) => {
            return transport.delete(`/api/admin/guesses/correct`, {
                participantId,
                guesses
            });
        },
        markCorrectGuesses: async (participantId, guesses) => {
            return transport.post(`/api/admin/guesses/correct`, {
                participantId,
                guesses
            });
        }
    };
};
