import { html } from 'lit';
import { auth } from '../../utils/auth';
export default function Masthead() {
    return html `
    <header class="bg-gray-900 text-white py-2 px-5 flex w-full">
      <a
        class="bg-transparent text-white text-2xl mr-auto inline-flex items-center font-light cursor-pointer no-underline"
        js-to-startpage
        href="/"
      >
        Maskorama-admin
      </a>

      <div class="flex items-center">
        <div class="relative z-30"></div>
        <div class="border-l border-gray-800 h-8 mr-2"></div>
        <div class="-mr-1 relative z-30">${auth.getUserName() || 'Bruker brukersson'}</div>
      </div>
    </header>
  `;
}
