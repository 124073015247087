import { UPDATE_CHECKLIST } from '../../events';
import { trigger } from '../../utils/events';
import { sessionStorage } from '../../utils/storage';
import { baseChecklist, resetfavorites } from './checklist';
export const resetChecklistStorage = () => {
    baseChecklist.forEach((item) => {
        sessionStorage.set(item.id, false);
    });
    trigger(UPDATE_CHECKLIST);
};
export const checkResetFavorite = () => {
    sessionStorage.set(resetfavorites, true);
    trigger(UPDATE_CHECKLIST);
};
