export const updateList = 'updateList';
export const screenshot = 'screenshot';
export const fewestFavorites = 'fewestFavorites';
export const markcorrect = 'markcorrect';
export const findWinner = 'findWinner';
export const revealparticipant = 'revealparticipant';
export const backToList = 'backToList';
export const sortParticipants = 'sortParticipants';
export const resetfavorites = 'resetfavorites';
export const unpauseVote = 'unpauseVote';
export const baseChecklist = [
    { id: updateList, text: 'Vent litt (+-30 sek), og så oppdater listen' },
    { id: screenshot, text: 'Ta skjermbilde av favoritter' },
    { id: fewestFavorites, text: 'Gå inn på deltager med færrest favoritter' },
    { id: markcorrect, text: 'Markere riktig gjett' },
    { id: findWinner, text: 'Finne vinner av Maskoramaboks og ta screenshot av vinneren' },
    { id: revealparticipant, text: 'Markere deltaker til avsløring' },
    { id: backToList, text: 'Gå tilbake til listen over alle deltakere' },
    { id: sortParticipants, text: 'Sortere deltakere og lagre rekkefølgen' },
    { id: resetfavorites, text: 'Nullstill favoritter' },
    { id: unpauseVote, text: 'Start avstemningen igjen (avslører deltaker til publikum)' }
];
