export default (transport) => {
    const api = {
        pause: async ({ time }) => {
            const newDate = new Date();
            if (Number.isInteger(time)) {
                newDate.setMinutes(newDate.getMinutes() + time);
            }
            return api.getRound().then((round) => {
                return transport.put(`/api/admin/rounds/${round.id}`, {
                    id: round.id,
                    endTime: newDate.toISOString()
                });
            });
        },
        unpause: async () => {
            return api.getRound().then((round) => {
                return transport.put(`/api/admin/rounds/${round.id}`, {
                    id: round.id,
                    endTime: null,
                    paused: false
                });
            });
        },
        getRound: async () => {
            return transport.get('/api/admin/rounds/current');
        }
    };
    return api;
};
