var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { off, on } from '../../utils/events';
import { ERROR_DIALOG } from '../../events';
import BaseElement from '../base/base-element';
let ErrorDialog = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ErrorDialog extends BaseElement {
    constructor() {
        super();
        this.isVisible = false;
        this.title = 'En feil har skjedd';
        this.content = '';
        this.toggleDialog = this.toggleDialog.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        on(ERROR_DIALOG, this.toggleDialog);
        this.addEventListener('dialog.toggle', this.onToggle);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        off(ERROR_DIALOG, this.toggleDialog);
        this.removeEventListener('dialog.toggle', this.onToggle);
    }
    // Needed so we can update state when the user closes the dialog
    onToggle(e) {
        this.isVisible = !e.target.hidden;
        this.requestUpdate();
    }
    toggleDialog(e) {
        if (e.showDialog) {
            this.isVisible = e.showDialog;
        }
        else {
            this.isVisible = false;
        }
        this.title = e.title;
        this.content = e.content;
        this.requestUpdate();
    }
    render() {
        return html `
      <pin-dialog ?hidden="${!this.isVisible}" strict>
        <div class="pin-dialog__header">
          <h1>${this.title}</h1>
        </div>
        <div class="pin-dialog__body">${this.content}</div>
        <footer class="pin-dialog__footer">
          <button class="pin-button pin-button--primary" for="close">Jeg forstår</button>
        </footer>
      </pin-dialog>
    `;
    }
};
__decorate([
    state()
], ErrorDialog.prototype, "isVisible", void 0);
__decorate([
    state()
], ErrorDialog.prototype, "title", void 0);
__decorate([
    state()
], ErrorDialog.prototype, "content", void 0);
ErrorDialog = __decorate([
    customElement('pin-error-dialog')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ErrorDialog);
export function getServerErrorContent() {
    return html `
    Kunne ikke oppdatere listen. Dette kan skje av forskjellige årsaker, som f.eks:
    <ul class="my-2">
      <li class="list-inside list-disc">Serverfeil</li>
      <li class="list-inside list-disc">Nettverksfeil</li>
      <li class="list-inside list-disc">Annet</li>
    </ul>

    <div>
      Maskorama-admin kommer fortsatt til å være tilgjengelig, men innholdet kan være utdatert og
      funksjoner kan være begrenset.
    </div>
  `;
}
