var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { customElement, property, state } from 'lit/decorators.js';
import kaleido from '@nrk/kaleido-ui';
import BaseElement from '../base/base-element';
import Masthead from '../masthead/masthead';
import api from '../../api';
const textMediaTypes = [
    {
        title: 'Dansetekst',
        type: 'dance_text'
    },
    {
        title: 'Favorittekst',
        type: 'favourite_text'
    },
    {
        title: 'Gjettekst',
        type: 'guess_text'
    }
];
const videoMediaTypes = [
    {
        title: 'Dansevideo',
        type: 'moving_still'
    },
    {
        title: 'Dansebilde',
        type: 'moving_still_poster'
    }
];
const mediaTypes = [
    {
        title: 'Listebilde',
        type: 'list_image',
        params: {},
        ratio: '256:290',
        sizes: {
            small: { width: 128, height: 145, format: 'png' }
        },
        format: 'png'
    }
];
let ParticipantSettings = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantSettings extends BaseElement {
    constructor() {
        super(...arguments);
        this.isUpdating = false;
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.participantId && this.participantId >= 0) {
            api.participants.get(this.participantId).then((data) => {
                this.data = data;
                this.requestUpdate();
            });
        }
        else {
            this.data = {
                id: this.participantId,
                maskName: '',
                mediaSet: []
            };
        }
        this.onChangeText = this.onChangeText.bind(this);
        this.addEventListener('change', this.onInputChange);
        this.addEventListener('image-editor-change', this.handleImageChange);
        this.addEventListener('image-picker-selected', this.handleImageSelected);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('change', this.onInputChange);
        this.removeEventListener('image-editor-change', this.handleImageChange);
        this.removeEventListener('image-picker-selected', this.handleImageSelected);
    }
    onInputChange(e) {
        if (e.target.hasAttribute('js-data-path')) {
            const path = e.target.getAttribute('js-data-path');
            this.data[path] = e.target.value;
            this.requestUpdate();
        }
    }
    handleImageChange(_e) {
        // const { imgid, params } = e.detail;
        // console.log(imgid, params);
        // kaleido.getDerivate(
        //   String,               // Kaleido image id. Example: 'AoHRgDViWwa_zycV1WcdOQ'
        //   Object {              // Options
        //     width: Number,      // Optional. Pixel width
        //     height: Number,     // Optional. Pixel height
        //     x: Number,          // Optional. [0->1] x offset of crop
        //     y: Number,          // Optional. [0->1] y offset of crop
        //     w: Number,          // Optional. [0->1] width of crop
        //     h: Number,          // Optional. [0->1] height of crop
        //     format: String      // Optional. Possible values: 'jpg', 'webp' and 'png'
        //   }
        // ).then((derivate))      // Returns promise with derivative data
        //
        // this.data = {
        //   ...this.data,
        //   media: {
        //     ...this.data.media,
        //     params: e.detail.params
        //   }
        // };
    }
    handleImageSelected(e) {
        this.getDerivate(e.detail.kaleidoId, { format: 'png' }).then((derivate) => {
            const index = this.data.mediaSet.findIndex((m) => m.type === e.detail.type);
            if (index >= 0) {
                this.data.mediaSet[index].href = derivate.url;
                this.data.mediaSet[index].properties.kaleidoId = derivate.id;
            }
            else {
                const mediaSet = {
                    href: derivate.url,
                    properties: { kaleidoId: derivate.id },
                    type: e.detail.type
                };
                this.data.mediaSet.push(mediaSet);
            }
            this.requestUpdate();
        });
    }
    getDerivate(id, params) {
        return kaleido.getDerivate(id, params);
    }
    async setImage(index, participant, { width, height, format, name }) {
        if (index >= 0) {
            const smallListImage = await this.getDerivate(participant.mediaSet[index].properties.kaleidoId, { format, width, height });
            participant.mediaSet[index].properties[name] = smallListImage.url;
        }
        return participant;
    }
    onChangeText(e) {
        const key = e.target.name;
        const value = e.target.value;
        const mediaSet = this.data.mediaSet.find((item) => item.type === key);
        if (mediaSet) {
            mediaSet.value = value;
        }
        else {
            this.data.mediaSet.push({
                type: key,
                value: value,
                properties: {},
                href: ''
            });
        }
    }
    onMediaChange(e) {
        const keys = e.target.name.split(':');
        const value = e.target.value;
        const mtype = keys[0];
        const mSize = keys[1];
        const mediaSet = this.data.mediaSet.find((item) => item.type === mtype);
        if (mediaSet) {
            mediaSet.properties[mSize] = value;
        }
        else {
            const newMediaSet = {
                type: mtype,
                value: '',
                properties: {
                    large: ''
                },
                href: ''
            };
            newMediaSet.properties[mSize] = value;
            this.data.mediaSet.push(newMediaSet);
        }
    }
    // We want to fetch different derivate sizes for some images
    async preSave(participant) {
        for (const mt of mediaTypes) {
            const sizes = mt.sizes;
            if (typeof sizes === 'undefined') {
                continue;
            }
            const index = participant.mediaSet.findIndex((m) => m.type === mt.type);
            for (const key of Object.keys(sizes)) {
                const size = sizes[key];
                participant = await this.setImage(index, participant, {
                    ...size,
                    name: key
                });
            }
        }
        return participant;
    }
    onSave() {
        const participant = {
            id: Number(this.participantId),
            maskName: this.data.maskName,
            mediaSet: this.data.mediaSet
        };
        this.isUpdating = true;
        this.preSave(participant).then(() => {
            if (typeof this.participantId === 'undefined') {
                api.participants.create(participant).then(() => {
                    this.isUpdating = false;
                });
            }
            else {
                api.participants.update(participant).then(() => {
                    this.isUpdating = false;
                });
            }
        });
    }
    render() {
        const hasLoaded = typeof this.data !== 'undefined';
        return html `
      ${Masthead()}
      <pin-participant-header
        .participant="${this.data}"
      ></pin-participant-header>

      <div class="block container mx-auto px-4 max-w-6xl">
        ${hasLoaded
            ? html `
              <div class="py-5 lg:w-2/4">
                <pin-view-input
                  label="Id"
                  value="${ifDefined(this.data.id)}"
                  path="id"
                ></pin-view-input>
                <pin-view-input
                  label="Navn på maske"
                  value="${ifDefined(this.data.maskName)}"
                  path="maskName"
                ></pin-view-input>
              </div>
              <div class="mb-10 lg:flex lg:items-start">
                ${mediaTypes.map((mediaType) => {
                var _a;
                const media = this.data.mediaSet.find((m) => m.type === mediaType.type);
                return html `
                    <div class="my-5 mx-4">
                      <label class="font-semibold">${mediaType.title} (${mediaType.type})</label>
                      <div
                        class="bg-gray-400 w-full overflow-none flex justify-center items-center mt-4 mb-2 h-64"
                      >
                        <pin-image-editor
                          class="flex-1"
                          kaleidoId="${ifDefined((_a = media === null || media === void 0 ? void 0 : media.properties) === null || _a === void 0 ? void 0 : _a.kaleidoId)}"
                          imgid="${mediaType.type}"
                          .params="${mediaType.params}"
                          format="${mediaType.ratio}"
                        ></pin-image-editor>
                      </div>

                      <pin-image-picker
                        label="Velg bilde"
                        type="${mediaType.type}"
                      ></pin-image-picker>
                    </div>
                  `;
            })}
              </div>
              <div>
                ${textMediaTypes.map((mediaType) => {
                const media = this.data.mediaSet.find((m) => m.type === mediaType.type);
                return html `
                    <div class="mb-4">
                      <label class="block font-semibold"> ${mediaType.title} </label>
                      <input
                        @keyup="${this.onChangeText}"
                        class="py-2 px-3 border border-gray-300 bg-gray-100 rounded w-full"
                        type="text"
                        value="${(media === null || media === void 0 ? void 0 : media.value) || ''}"
                        name="${mediaType.type}"
                      />
                    </div>
                  `;
            })}
              </div>
              <div>
                ${videoMediaTypes.map((mediaType) => {
                var _a;
                const media = this.data.mediaSet.find((m) => m.type === mediaType.type);
                return html `
                    <div class="mb-4">
                      <div>
                        <label class="block font-semibold"> ${mediaType.title} - Large </label>
                        <input
                          @keyup="${this.onMediaChange}"
                          class="py-2 px-3 border border-gray-300 bg-gray-100 rounded w-full"
                          type="text"
                          value="${((_a = media === null || media === void 0 ? void 0 : media.properties) === null || _a === void 0 ? void 0 : _a.large) || ''}"
                          name="${mediaType.type}:large"
                        />
                      </div>
                    </div>
                  `;
            })}
              </div>

              <div class="">
                <button
                  class="py-2 px-5 rounded font-semibold text-sm disabled:cursor-not-allowed bg-blue-700 text-white disabled:bg-blue-300"
                  @click="${this.onSave}"
                  ?disabled="${this.isUpdating}"
                >
                  ${this.isUpdating ? 'Lagrer...' : 'Lagre'}
                </button>
              </div>
            `
            : html ` <h1>Laster inn deltaker....</h1> `}
      </div>
    `;
    }
};
__decorate([
    property({ type: Number })
], ParticipantSettings.prototype, "participantId", void 0);
__decorate([
    state()
], ParticipantSettings.prototype, "data", void 0);
__decorate([
    state()
], ParticipantSettings.prototype, "isUpdating", void 0);
ParticipantSettings = __decorate([
    customElement('pin-participant-settings')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantSettings);
