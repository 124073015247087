import { html } from 'lit';
import GuessActions from './guess-actions';
export default function GuessTableTop({ markGroupDisabled, isCreatingGroup, closeDropdown, createGroup, markCorrectDisabled, isMarkingGuess, hasSelectedIncorrectGuess, markGuess, groupName, localPagination, onChange }) {
    return html `
    <div class="mb-8 flex flex-row justify-between">
      <div class="flex w-1/2">
        <input
          name="guess-search"
          class="py-2 px-3 border border-gray-300 rounded w-1/3"
          placeholder="Søk i forslag"
          data-lpignore="true"
        />
        <label class="ml-2 py-2">
          <input
            type="checkbox"
            name="local-pagination"
            .checked=${localPagination}
            @change=${onChange}
          />
          Avansert søk
        </label>
      </div>
      <div class="w-1/2 flex items-center justify-end">
        ${GuessActions({
        isCreatingGroup,
        markGroupDisabled,
        closeDropdown,
        createGroup,
        markCorrectDisabled,
        isMarkingGuess,
        hasSelectedIncorrectGuess,
        markGuess,
        groupName
    })}
      </div>
    </div>
  `;
}
