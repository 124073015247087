var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { orgArrowLeft, orgArrowLeftEnd, orgArrowRight } from '@nrk/origo';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { customElement, property, query } from 'lit/decorators.js';
import { GUESSES_SET_PAGE } from '../../events';
import { trigger } from '../../utils/events';
import BaseElement from '../base/base-element';
let ParticipantPagination = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantPagination extends BaseElement {
    constructor() {
        super();
        this.page = 1;
        this.nextDisabled = false;
        this.previousDisabled = false;
        this.setPage = this.setPage.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    setPage(e) {
        let newPage = this.page;
        const element = e.target.closest('[js-value]');
        const value = (element === null || element === void 0 ? void 0 : element.getAttribute('js-value')) || null;
        if (value === 'first') {
            newPage = 1;
        }
        else if (value === 'previous') {
            newPage = Math.max(this.page - 1, 1);
        }
        else if (value === 'next') {
            newPage = this.page + 1;
        }
        trigger(GUESSES_SET_PAGE, newPage);
    }
    onChange(e) {
        trigger(GUESSES_SET_PAGE, e.target.value);
    }
    render() {
        return html `
      <div class="pin-pagination ml-2 flex flex-row items-center">
        <button
          class="flex items-center pin-button pin-button--transparent"
          @click="${this.setPage}"
          js-value="first"
          ?disabled="${this.previousDisabled}"
        >
          ${unsafeHTML(orgArrowLeftEnd)}Første
        </button>
        <button
          class="flex items-center pin-button pin-button--transparent"
          @click="${this.setPage}"
          js-value="previous"
          ?disabled="${this.previousDisabled}"
        >
          ${unsafeHTML(orgArrowLeft)}Forrige
        </button>

        <input
          name="guess-pagination"
          type="number"
          class="py-2 px-2 border border-gray-300 bg-gray-100 rounded text-center mr-2"
          data-lpignore="true"
          .value="${this.page}"
          aria-label="Viser side ${this.page}"
          style="width: 50px;"
          @change="${this.onChange}"
        />
        <button
          class="flex items-center pin-button pin-button--transparent"
          @click="${this.setPage}"
          js-value="next"
          ?disabled="${this.nextDisabled}"
        >
          Neste ${unsafeHTML(orgArrowRight)}
        </button>
      </div>
    `;
    }
};
__decorate([
    property({ type: Number })
], ParticipantPagination.prototype, "page", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantPagination.prototype, "nextDisabled", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantPagination.prototype, "previousDisabled", void 0);
__decorate([
    query('[name="guess-pagination"]')
], ParticipantPagination.prototype, "inputEl", void 0);
ParticipantPagination = __decorate([
    customElement('pin-participant-pagination')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantPagination);
