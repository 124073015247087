import participants from './participants';
import favorites from './favorites';
import rounds from './rounds';
import guess from './guess';
import transportFactory from './transport';
import config from '../config/config';
const transport = transportFactory(`${config.API_BASE_URL}`);
export default {
    participants: participants(transport),
    favorites: favorites(transport),
    rounds: rounds(transport),
    guess: guess(transport)
};
