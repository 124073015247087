const ALLOWED_EL_TYPES = ['button', 'a'];
export const BUTTON_TYPE = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    TERTIARY: 'TERTIARY'
};
export default function Button({ text = '', additionalClassNames = '', type = BUTTON_TYPE.PRIMARY, elType = 'button', href = '', attributes = [] }) {
    if (!ALLOWED_EL_TYPES.includes(elType)) {
        throw new Error('Button does not support elType: ' + elType);
    }
    let classNames = 'py-2 px-5 rounded font-semibold text-sm disabled:cursor-not-allowed ';
    if (type === BUTTON_TYPE.PRIMARY) {
        classNames += 'bg-blue-700 text-white disabled:bg-blue-300 ';
    }
    else if (type === BUTTON_TYPE.SECONDARY) {
        classNames += 'bg-gray-200 text-black ';
    }
    else {
        classNames += 'bg-transparent border-dashed border border-gray-400';
    }
    classNames += additionalClassNames;
    const button = document.createElement(elType);
    if (elType === 'a') {
        classNames += ' no-underline';
        button.setAttribute('href', href);
    }
    button.className = classNames;
    button.textContent = text;
    attributes.forEach((attr) => {
        button.setAttribute(attr.name, attr.value);
    });
    // const attrs = attributes
    //   ? attributes.map(a => `${a.name}="${a.value}"`).join(" ")
    //   : nothing;
    // console.log("attrs: ", attrs);${unsafeHTML(attrs)}
    return button;
}
