import { html } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
export default function LabelledInput({ label, name, type = 'text', value = '', className = '', warning, isButton, isRequired, isInvalid, path, hint }) {
    const labelText = html `
    <span class="inline-block font-semibold pin-text-label"
      >${label} ${isRequired ? ' *' : ''}</span
    >
  `;
    const after = type === 'checkbox';
    const labelClass = isButton ? `mb-5 block ${className}` : className;
    return html `
    <label class="${labelClass}">
      ${after ? '' : labelText}
      ${hint
        ? html `
            <span class="block text-gray-700 text-sm tracking-wider" style="font-size: 12px;"
              >${hint}</span
            >
          `
        : ''}
      <input
        name="${name}"
        type="${type}"
        value="${value}"
        class="py-2 px-3 border border-gray-300 bg-gray-100 rounded w-full"
        data-lpignore="true"
        ?required="${isRequired}"
        aria-invalid="${isInvalid ? 'true' : 'false'}"
        js-data-path="${ifDefined(path)}"
      />
      ${after ? labelText : ''}
      <span class="org-warning">${warning}</span>
    </label>
  `;
}
