var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BaseElement from '../base/base-element';
import { customElement, property } from 'lit/decorators.js';
let Tile = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Tile extends BaseElement {
    constructor() {
        super();
        this.extraClasses = '';
        this.isRevealed = false;
        this.isRescued = false;
        this.fewestFavorites = false;
        this.setTileStyling();
    }
    attributeChangedCallback(name, oldVal, newVal) {
        super.attributeChangedCallback(name, oldVal, newVal);
        if (oldVal !== newVal) {
            this.setTileStyling();
        }
    }
    setTileStyling() {
        this.className =
            this.extraClasses +
                ' px-5 py-3 mb-1 min-h-16 flex items-center justify-end shadow rounded text-gray-800 text-sm hover:bg-yellow-200 focus:outline-none focus:shadow-outline focus:relative focus:z-10 no-underline';
        if (this.fewestFavorites) {
            this.className += ' bg-red-300';
        }
        else if (this.isRevealed) {
            this.className += ' bg-purple-200 opacity-75';
        }
        else if (this.isRescued) {
            this.className += ' bg-purple-500 hover:bg-purple-400 opacity-90';
        }
        else {
            this.className += ' bg-white';
        }
    }
};
__decorate([
    property({ type: String })
], Tile.prototype, "extraClasses", void 0);
__decorate([
    property({ type: Boolean })
], Tile.prototype, "isRevealed", void 0);
__decorate([
    property({ type: Boolean })
], Tile.prototype, "isRescued", void 0);
__decorate([
    property({ type: Boolean })
], Tile.prototype, "fewestFavorites", void 0);
Tile = __decorate([
    customElement('pin-tile')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], Tile);
