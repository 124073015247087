import { html, render } from 'lit';
const root = document.querySelector('.pin-container');
export const setContent = function (content) {
    if (root !== null) {
        render(content, root);
    }
    else {
        console.error('Unable to find root container: .pin-container');
    }
};
const applyRouter = function (router) {
    router.on(function () {
        setContent(html `
      <pin-participants-page></pin-participants-page>
      <pin-data-error-dialog></pin-data-error-dialog>
      <pin-error-dialog></pin-error-dialog>
    `);
    });
    router.on('/participants', function () {
        setContent(html `
      <pin-participants-page></pin-participants-page>
      <pin-data-error-dialog></pin-data-error-dialog>
      <pin-error-dialog></pin-error-dialog>
    `);
    });
    router.on('/participants/new', function () {
        setContent(html ` <pin-participant-settings></pin-participant-settings> `);
    });
    router.on('/participants/:id', function (match) {
        var _a;
        setContent(html `
      <pin-participant-page participantId="${Number((_a = match === null || match === void 0 ? void 0 : match.data) === null || _a === void 0 ? void 0 : _a.id) || -1}"></pin-participant-page>
      <pin-data-error-dialog></pin-data-error-dialog>
      <pin-error-dialog></pin-error-dialog>
    `);
    });
    router.on('/participants/:id/settings', function (match) {
        var _a;
        setContent(html `
      <pin-participant-settings
        participantId="${Number((_a = match === null || match === void 0 ? void 0 : match.data) === null || _a === void 0 ? void 0 : _a.id) || -1}"
      ></pin-participant-settings>
    `);
    });
    router.on('/rescued', function () {
        setContent(html ` <pin-rescuedparticipant-page></pin-rescuedparticipant-page> `);
    });
};
export default applyRouter;
