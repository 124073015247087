import kaleido from '@nrk/kaleido-ui';
import { LitElement, html } from 'lit';
import Button, { BUTTON_TYPE } from '../form/button';

export default class ImagePicker extends LitElement {
  static get properties() {
    return {
      label: { type: String },
      type: { type: String }
    };
  }

  constructor() {
    super();
    this.className = 'block';
    this.label = 'Velg bilde';
    this.onImageChange = this.onImageChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('click', this.handleEvent);
  }

  createRenderRoot() {
    return this;
  }

  onImageChange({ selected }) {
    this.dispatchEvent(
      new CustomEvent('image-picker-selected', {
        bubbles: true,
        detail: {
          kaleidoId: selected,
          type: this.type
        }
      })
    );
  }

  handleEvent(e) {
    if (e.target.closest('[js-choose-image]')) {
      kaleido.picker({
        search: '',
        onChange: this.onImageChange
      });
    }
  }

  render() {
    return html`
      ${Button({
        text: this.label,
        type: BUTTON_TYPE.TERTIARY,
        attributes: [
          {
            name: 'js-choose-image',
            value: ''
          }
        ]
      })}
    `;
  }
}
// Define if not already defined
if (!window.customElements.get('pin-image-picker')) {
  window.customElements.define('pin-image-picker', ImagePicker);
}
