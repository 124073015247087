var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import BaseElement from '../base/base-element';
import api from '../../api';
import { off, on, trigger } from '../../utils/events';
import { suggestGroupName, getValueOfCheckedElements } from './participant-utils';
import { GuessTableHeader } from './guess-table-header';
import { sessionStorage } from '../../utils/storage';
import { ERROR_DIALOG, GUESSES_FETCH, GUESSES_UPDATED, GUESSES_SET_PAGE } from '../../events';
import { getCheckedGuessElements, getCheckedGuesses, getGroupingInformation, createNewGroup, updatePinnedGuesses, removePinnedGuesses } from '../guesses/guess-services';
import GuessTableBody from './guess-table-body';
import GuessTableFooter from './guess-table-footer';
import LoaderInline from '../loader-inline';
import GuessTableTop from './guess-table-top';
const { get, set } = sessionStorage;
let ParticipantGuesses = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantGuesses extends BaseElement {
    constructor() {
        var _a;
        super();
        this.participant = null;
        this.participantId = -1;
        this.isPaused = false;
        this.isRevealingParticipant = false;
        this.isEditingRevealedParticipant = false;
        this.isMarkingGuess = false;
        this.isCreatingGroup = false;
        this.isLoading = false;
        this.guesses = [];
        this.filteredGuesses = [];
        this.pinnedGuesses = [];
        this.users = {};
        this.totalGuesses = 0;
        this.filteredTotalGuesses = 0;
        this.currentPage = 1;
        this.limit = 100;
        this.skip = 0;
        this.desc = true;
        this.nextDisabled = false;
        this.previousDisabled = true;
        this.markGroupDisabled = true;
        this.markCorrectDisabled = true;
        this.hasSelectedIncorrectGuess = true;
        this.groupName = '';
        this.sortType = get('guessSortType') || '';
        this.sortDirection = get('guessSortDirection') || 'desc';
        this.search = '';
        this.localPagination = (_a = get('local-pagination')) !== null && _a !== void 0 ? _a : true;
        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.setSort = this.setSort.bind(this);
        this.setPage = this.setPage.bind(this);
        this.getGuesses = this.getGuesses.bind(this);
        this.markGuess = this.markGuess.bind(this);
        this.onLocalPaginationChange = this.onLocalPaginationChange.bind(this);
        this.updateDisabled = this.updateDisabled.bind(this);
        this.updateCheckedElements = this.updateCheckedElements.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.getGuesses();
        on(GUESSES_FETCH, this.getGuesses);
        on(GUESSES_SET_PAGE, this.setPage);
        this.addEventListener('click', this.onClick);
        this.addEventListener('change', this.onChange);
        this.addEventListener('toggle', this.onToggle);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        off(GUESSES_FETCH, this.getGuesses);
        off(GUESSES_SET_PAGE, this.setPage);
        this.removeEventListener('click', this.onClick);
        this.removeEventListener('change', this.onChange);
    }
    triggerFetchUpdate() {
        trigger(GUESSES_FETCH);
    }
    onChange(e) {
        var _a, _b;
        const target = e.target;
        if (target.name === 'guess-search') {
            if (this.search !== e.target.value) {
                // Ensure we're on page 1 when we're starting a new search
                this.skip = 0;
                this.currentPage = 1;
            }
            this.search = e.target.value;
            this.getGuesses();
        }
        else if (target.closest("[name='guess-names']")) {
            this.setGroupName();
            this.setCorrectOrIncorrectMode();
        }
        else if (target.closest("[name='group-name']")) {
            this.setGroupName((_a = target.closest("[name='group-name']")) === null || _a === void 0 ? void 0 : _a.value);
        }
        else if (target.closest('[js-check-all]')) {
            const check = (_b = target.closest('[js-check-all]')) === null || _b === void 0 ? void 0 : _b.checked;
            document
                .querySelectorAll('input[type="checkbox"][name="guess-names"]')
                .forEach((checkBox) => {
                checkBox.checked = !!check;
            });
            this.updateCheckedElements();
        }
    }
    onToggle(e) {
        if (!e.target.hidden && e.target.hasAttribute('js-guess-name')) {
            const guessName = e.target.getAttribute('js-guess-name');
            this.getRandomUsers(guessName);
        }
    }
    onLocalPaginationChange(_e) {
        this.localPagination = !this.localPagination;
        set('local-pagination', this.localPagination);
        if (this.search) {
            this.currentPage = 1;
            this.skip = 0;
            this.getGuesses();
        }
    }
    getRandomUsers(guessName) {
        api.participants.getRandomUsersWithGuessedName(this.participantId, guessName).then((people) => {
            this.users = { ...this.users, [guessName]: people };
            this.requestUpdate();
        });
    }
    setCorrectOrIncorrectMode() {
        const checkedEls = [...this.querySelectorAll("[name='guess-names']")]
            .filter((g) => g.checked)
            .map((g) => g.value);
        this.hasSelectedIncorrectGuess = this.filteredGuesses
            .filter((g) => checkedEls.includes(g.name))
            .some((g) => !g.correct);
        this.requestUpdate();
    }
    createGroup() {
        this.isCreatingGroup = true;
        const groupName = this.groupName.trim();
        const { participantId, guesses, pinnedGuesses } = this;
        const checkedGuessElements = getCheckedGuessElements(document.body);
        if (groupName && groupName.length > 0 && checkedGuessElements.length >= 2) {
            const checkedPinnedGuesses = getCheckedGuesses(pinnedGuesses, checkedGuessElements);
            const checkedGuesses = getCheckedGuesses(guesses, checkedGuessElements);
            const { isNewGroupPinned, movePinnedGuessToNonPinnedGroup } = getGroupingInformation({
                groupName: this.groupName,
                checkedPinnedGuesses
            });
            const newGroup = createNewGroup({
                groupName,
                participantId,
                checkedGuesses,
                checkedPinnedGuesses
            });
            api.participants.createGroup(groupName, newGroup).then(() => {
                if (isNewGroupPinned) {
                    updatePinnedGuesses(this.pinnedGuesses, this.participantId).then((pinnedGuesses) => {
                        this.pinnedGuesses = pinnedGuesses;
                        this.requestUpdate();
                    });
                }
                else if (movePinnedGuessToNonPinnedGroup) {
                    this.pinnedGuesses = removePinnedGuesses({
                        pinnedGuesses: this.pinnedGuesses,
                        checkedPinnedGuesses
                    });
                }
                const dropdown = document.querySelector('[js-dropdown-group]:not([hidden])');
                if (dropdown) {
                    dropdown.hidden = true;
                }
                this.getGuesses();
            });
        }
    }
    editGroup(groupName, newGroupName) {
        const guess = [...this.pinnedGuesses, ...this.filteredGuesses].find((g) => g.name === groupName);
        newGroupName = newGroupName.trim();
        if (guess) {
            api.participants
                .createGroup(groupName, {
                participantId: this.participantId,
                groupName: newGroupName.trim(),
                guessedNames: guess.guessedNames
            })
                .then(() => {
                const index = this.pinnedGuesses.findIndex((guess) => guess.name === groupName);
                if (index >= 0) {
                    this.pinnedGuesses[index].name = newGroupName;
                }
                this.getGuesses();
            });
        }
        else {
            console.error(`Unable to find the group "${groupName}" and editing it.`);
        }
    }
    removeGroup(name) {
        api.participants
            .createGroup(name, {
            participantId: this.participantId,
            groupName: name,
            guessedNames: []
        })
            .then(() => {
            this.pinnedGuesses = this.pinnedGuesses.filter((guess) => guess.name !== name);
            this.getGuesses();
        });
    }
    removeNameFromGroup(el) {
        const groupName = el.getAttribute('js-group');
        const removeName = el.getAttribute('js-remove-name');
        const guess = [...this.filteredGuesses, ...this.pinnedGuesses].find((g) => g.name === groupName);
        if (guess && guess.guessedNames && groupName !== null) {
            const guessedNames = guess.guessedNames.filter((g) => g !== removeName);
            api.participants
                .createGroup(groupName, {
                participantId: this.participantId,
                groupName,
                guessedNames
            })
                .then(() => {
                updatePinnedGuesses(this.pinnedGuesses, this.participantId).then((pinnedGuesses) => {
                    this.pinnedGuesses = pinnedGuesses;
                    this.requestUpdate();
                });
                this.getGuesses();
            });
        }
        else {
            console.error(`Unable to find the group "${groupName}", thus cannot remove name from group.`);
        }
    }
    updateDisabled() {
        const maxLength = this.localPagination && this.search ? this.currentPage * this.limit : this.limit;
        if (this.guesses.length < maxLength) {
            this.nextDisabled = true;
        }
        else {
            this.nextDisabled = false;
        }
        if (this.currentPage > 1) {
            this.previousDisabled = false;
        }
        else {
            this.previousDisabled = true;
        }
    }
    async getGuesses() {
        this.isLoading = true;
        try {
            const data = await api.participants.search({
                id: this.participantId,
                search_starts_with: this.localPagination && this.search ? '' : this.search,
                search_query: this.localPagination && this.search ? this.search : '',
                limit: this.limit,
                skip: (this.currentPage - 1) * this.limit,
                desc: this.desc
            });
            this.guesses = data.summary;
            this.totalGuesses = data.total;
            this.filteredTotalGuesses = data.filteredTotal;
            if (this.localPagination && this.search) {
                this.filteredGuesses = this.guesses.slice(this.limit * (this.currentPage - 1), this.limit * this.currentPage);
            }
            else {
                this.filteredGuesses = this.guesses;
            }
            this.updateDisabled();
            this.setCorrectOrIncorrectMode();
            this.isMarkingGuess = false;
            this.isCreatingGroup = false;
            trigger(GUESSES_UPDATED, Date.now());
            this.isLoading = false;
            this.requestUpdate();
        }
        catch (e) {
            console.log(e);
            this.isLoading = false;
            trigger(GUESSES_UPDATED, null);
        }
    }
    markGuess() {
        const checkedGuessElements = getCheckedGuessElements(document.body);
        const guessNames = checkedGuessElements.map((g) => g.value);
        // Fetch all the names in the group and the groupname itself
        const guessesMap = this.filteredGuesses
            .filter((g) => guessNames.includes(g.name))
            .reduce((acc, c) => {
            if (c.guessedNames !== undefined) {
                c.guessedNames.forEach((name) => acc.push(name));
            }
            acc.push(c.name);
            return acc;
        }, []);
        // Ensure we have unique entries
        const guesses = Array.from(new Set(guessesMap));
        this.isMarkingGuess = true;
        if (this.hasSelectedIncorrectGuess) {
            api.guess
                .markCorrectGuesses(this.participantId, guesses)
                .then(this.getGuesses)
                .catch((err) => {
                console.error('markCorrectGuesses: ', err);
                trigger(ERROR_DIALOG, {
                    showDialog: true,
                    title: 'Feil ved markering av forslag',
                    content: 'Vi hadde problemer med å markere forslag(ene) som riktig. Sjekk console for mer informasjon.'
                });
                this.isMarkingGuess = false;
            });
        }
        else {
            api.guess
                .markIncorrectGuesses(this.participantId, guesses)
                .then(this.getGuesses)
                .catch((err) => {
                console.error('markIncorrectGuesses: ', err);
                trigger(ERROR_DIALOG, {
                    showDialog: true,
                    title: 'Feil ved markering av forslag',
                    content: 'Vi hadde problemer med å fjerne markering av forslag(ene) som riktig. Sjekk console for mer informasjon.'
                });
                this.isMarkingGuess = false;
            });
        }
    }
    getSortedGuesses() {
        if (this.sortType === 'correct') {
            const copyGuesses = [...this.filteredGuesses];
            return copyGuesses.sort((a, b) => {
                if (this.sortDirection === 'desc') {
                    return !a.correct && b.correct ? 1 : -1;
                }
                else if (this.sortDirection === 'asc') {
                    return a.correct && !b.correct ? 1 : -1;
                }
                else {
                    return 0;
                }
            });
        }
        return this.filteredGuesses;
    }
    setSort(e) {
        const target = e.target.closest('button');
        const sortType = target.getAttribute('js-sort-type');
        const sortDirection = target.getAttribute('js-sort-direction');
        set('guessSortType', sortType);
        set('guessSortDirection', sortDirection);
        if (sortType && sortType === 'count') {
            this.sortType = sortType;
            this.sortDirection = sortDirection;
            this.desc = sortDirection === 'desc';
            this.getGuesses();
        }
        else if (sortType && sortDirection) {
            this.sortType = sortType;
            this.sortDirection = sortDirection;
            this.requestUpdate();
        }
    }
    onClick(e) {
        var _a, _b, _c, _d;
        const target = e.target;
        if (target.closest('[js-pin-guess]')) {
            const name = (_a = target.closest('[js-pin-guess]')) === null || _a === void 0 ? void 0 : _a.getAttribute('js-pin-guess');
            if (name) {
                this.pinGuess(name);
            }
        }
        else if (target.closest('[js-remove-name]')) {
            const el = target.closest('[js-remove-name]');
            if (el) {
                this.removeNameFromGroup(el);
            }
        }
        else if (target.closest('[js-remove-group')) {
            const name = (_b = target.closest('[js-remove-group')) === null || _b === void 0 ? void 0 : _b.getAttribute('js-remove-group');
            if (name) {
                this.removeGroup(name);
            }
        }
        else if (target.closest('[js-fetch-users]')) {
            const guessName = (_c = target.closest('[js-fetch-users]')) === null || _c === void 0 ? void 0 : _c.getAttribute('js-guess-name');
            if (guessName) {
                this.getRandomUsers(guessName);
            }
        }
        else if (target.closest('[js-edit-group]')) {
            const groupName = (_d = target.closest('[js-edit-group]')) === null || _d === void 0 ? void 0 : _d.getAttribute('js-edit-group');
            const newGroupName = this.querySelector(`[name="edit-${groupName}"]`)
                .value;
            if (groupName && newGroupName) {
                this.editGroup(groupName, newGroupName);
            }
        }
        else if (target.closest('[js-close-dropdown]')) {
            const dropdown = target.closest('pin-dropdown');
            dropdown.hidden = true;
        }
        else if (target.closest('[js-sort]')) {
            this.setSort(e);
        }
    }
    setMarkGroupDisabled(checkedElsLength) {
        if (checkedElsLength >= 2) {
            this.markGroupDisabled = false;
        }
        else {
            this.markGroupDisabled = true;
        }
    }
    setMarkCorrectDisabled(checkedElsLength) {
        if (checkedElsLength === 0) {
            this.markCorrectDisabled = true;
        }
        else {
            this.markCorrectDisabled = false;
        }
    }
    pinGuess(name) {
        const guess = this.filteredGuesses.find((g) => g.name === name);
        if (guess) {
            if (this.pinnedGuesses.findIndex((pg) => pg.name === name) >= 0) {
                this.pinnedGuesses = this.pinnedGuesses.filter((pg) => pg.name !== name);
            }
            else {
                this.pinnedGuesses.push(guess);
            }
        }
        else {
            this.pinnedGuesses = this.pinnedGuesses.filter((pg) => pg.name !== name);
        }
        this.requestUpdate();
    }
    updateCheckedElements() {
        const checkedEls = getValueOfCheckedElements(this.querySelectorAll("[name='guess-names']"));
        this.setMarkGroupDisabled(checkedEls.length);
        this.setMarkCorrectDisabled(checkedEls.length);
        this.requestUpdate();
    }
    setGroupName(groupName) {
        if (groupName) {
            this.groupName = groupName;
            return;
        }
        const checkedEls = getValueOfCheckedElements(this.querySelectorAll("[name='guess-names']"));
        const nonPinnedGuesses = this.filteredGuesses.filter((g) => this.pinnedGuesses.findIndex((pg) => pg.name === g.name) < 0);
        const guesses = [...this.pinnedGuesses, ...nonPinnedGuesses].filter((g) => checkedEls.includes(g.name));
        this.setMarkGroupDisabled(checkedEls.length);
        this.setMarkCorrectDisabled(checkedEls.length);
        this.groupName = suggestGroupName(guesses);
        this.requestUpdate();
    }
    closeDropdown(e) {
        const dropdown = e.target.closest('pin-dropdown');
        if (dropdown) {
            dropdown.hidden = true;
        }
    }
    setPage(page) {
        if (page !== this.currentPage) {
            this.currentPage = page;
            if (this.localPagination && this.search) {
                this.filteredGuesses = this.guesses.slice(this.limit * (this.currentPage - 1), this.limit * this.currentPage);
                this.updateDisabled();
            }
            else {
                this.getGuesses();
            }
        }
    }
    render() {
        const guesses = this.getSortedGuesses();
        const nonPinnedGuesses = guesses.filter((g) => this.pinnedGuesses.findIndex((pg) => pg.name === g.name) < 0);
        const startingItemNumber = (this.currentPage - 1) * this.limit + 1;
        const endingItemNumber = startingItemNumber + nonPinnedGuesses.length + this.pinnedGuesses.length - 1;
        const isRevealed = this.participant && this.participant.realName;
        if (this.participant === null) {
            return html `<div>Klarer ikke å hente deltaker</div>`;
        }
        return html `
      <div class="mb-4 pin-text-ingress">
        Viser forslag
        <span class="font-bold">${startingItemNumber} - ${endingItemNumber}</span>
        (med totalt
        <span class="font-bold">${this.filteredTotalGuesses}</span> antall gjett)
      </div>
      ${isRevealed && this.isPaused
            ? html `<pin-participant-revealed-actions
            .participant="${this.participant}"
            ?isRevealingParticipant="${this.isRevealingParticipant}"
            ?isEditingRevealedParticipant="${this.isEditingRevealedParticipant}"
          ></pin-participant-revealed-actions>`
            : ''}

      <div class="pin-table w-full">
        ${GuessTableTop({
            markGroupDisabled: this.markGroupDisabled,
            isCreatingGroup: this.isCreatingGroup,
            closeDropdown: this.closeDropdown,
            createGroup: this.createGroup,
            markCorrectDisabled: this.markCorrectDisabled,
            isMarkingGuess: this.isMarkingGuess,
            hasSelectedIncorrectGuess: this.hasSelectedIncorrectGuess,
            markGuess: this.markGuess,
            groupName: this.groupName,
            localPagination: this.localPagination,
            onChange: this.onLocalPaginationChange
        })}
        ${GuessTableHeader(this.sortDirection, this.sortType)}
        <div class="relative">
          ${GuessTableBody({
            nonPinnedGuesses,
            pinnedGuesses: this.pinnedGuesses,
            totalGuesses: this.totalGuesses,
            users: this.users,
            isLoading: this.isLoading
        })}
          ${this.isLoading && nonPinnedGuesses.length > 0
            ? html `
                <div
                  class="absolute w-full h-full top-0 flex items-center justify-center cursor-not-allowed"
                >
                  ${LoaderInline('text-4xl')}
                </div>
              `
            : ''}
        </div>
      </div>

      ${GuessTableFooter({
            currentPage: this.currentPage,
            nextDisabled: this.nextDisabled,
            previousDisabled: this.previousDisabled,
            markGroupDisabled: this.markGroupDisabled,
            isCreatingGroup: this.isCreatingGroup,
            closeDropdown: this.closeDropdown,
            createGroup: this.createGroup,
            markCorrectDisabled: this.markCorrectDisabled,
            isMarkingGuess: this.isMarkingGuess,
            hasSelectedIncorrectGuess: this.hasSelectedIncorrectGuess,
            markGuess: this.markGuess,
            groupName: this.groupName
        })}
    `;
    }
};
__decorate([
    property({ type: Object })
], ParticipantGuesses.prototype, "participant", void 0);
__decorate([
    property({ type: Number })
], ParticipantGuesses.prototype, "participantId", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantGuesses.prototype, "isPaused", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantGuesses.prototype, "isRevealingParticipant", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantGuesses.prototype, "isEditingRevealedParticipant", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantGuesses.prototype, "isMarkingGuess", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantGuesses.prototype, "isCreatingGroup", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "isLoading", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "guesses", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "filteredGuesses", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "pinnedGuesses", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "users", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "totalGuesses", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "filteredTotalGuesses", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "currentPage", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "limit", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "skip", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "desc", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "nextDisabled", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "previousDisabled", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "markGroupDisabled", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "markCorrectDisabled", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "hasSelectedIncorrectGuess", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "groupName", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "sortType", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "sortDirection", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "search", void 0);
__decorate([
    state()
], ParticipantGuesses.prototype, "localPagination", void 0);
ParticipantGuesses = __decorate([
    customElement('pin-participant-guesses')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantGuesses);
