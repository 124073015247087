/**
 * Compares two variables if they are equal (does deep equality)
 * @param {any} x
 * @param {any} y
 * @returns {boolean} Returns true|false depending on if the variables are equal
 */
export const deepEqual = function (x, y) {
    if (x === y) {
        return true;
    }
    else if (typeof x === 'object' && x != null && typeof y === 'object' && y !== null) {
        if (Object.keys(x).length !== Object.keys(y).length) {
            return false;
        }
        for (const prop in x) {
            if (Object.prototype.hasOwnProperty.call(y, prop)) {
                if (!deepEqual(x[prop], y[prop])) {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        return true;
    }
    else {
        return false;
    }
};
export const immutableUpdate = function (obj, path, value) {
    if (typeof path === 'string') {
        const paths = path.match(/[^\][.]+/g);
        if (paths === null) {
            return { ...obj };
        }
        return { ...immutableUpdate(obj, paths, value) };
    }
    else if (path.length > 1) {
        return Object.assign(obj, {
            [path[0]]: immutableUpdate(obj[path[0]], path.slice(1), value)
        });
    }
    else if (path.length === 1) {
        if (obj[path[0]] instanceof Array && typeof value === 'string') {
            return Object.assign(obj, {
                [path[0]]: value.split(',')
            });
        }
        else {
            return Object.assign(obj, {
                [path[0]]: value
            });
        }
    }
};
