import BaseElement from '../base/base-element';

class Droppable extends BaseElement {
  constructor() {
    super();
    this.classList.add('pin-droppable', 'block');
  }
}

if (!window.customElements.get('pin-droppable')) {
  window.customElements.define('pin-droppable', Droppable);
}
