/**
 * suggestGroupName - will suggest a group name based on items
 * 1: If there are no items that is a group, suggest an empty string
 * 2: If there is exactly one item that is a group, suggest the group name
 * 3: If there are more than one item that is a group, suggest an empty string
 *
 * @param array items can be guesses or groups of guesses
 * @returns string the suggested group name
 */
export function suggestGroupName(items = []) {
    if (items.length === 0) {
        console.info('suggestGroupName: sending empty items list');
    }
    const hasExactlyOneItemWithGroup = items.filter((i) => 'guessedNames' in i).length === 1;
    if (hasExactlyOneItemWithGroup) {
        const selectedItem = items.find((i) => 'guessedNames' in i);
        return (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.name) || '';
    }
    else {
        return '';
    }
}
export function getValueOfCheckedElements(elements = []) {
    return [...elements].filter((g) => g.checked).map((g) => g.value);
}
