const events = {};
export const on = function (key, func) {
    if (!events[key]) {
        events[key] = [];
    }
    events[key].push(func);
};
export const off = function (key, func) {
    if (events[key]) {
        const index = events[key].findIndex((f) => f === func);
        events[key].splice(index, 1);
    }
};
export const trigger = function (key, ...args) {
    const prefixKey = key.split(':')[0];
    if (events[key]) {
        events[key].forEach(function (func) {
            func(...args);
        });
    }
    if (prefixKey !== key && events[prefixKey]) {
        events[prefixKey].forEach(function (func) {
            func(key, ...args);
        });
    }
};
