const configEl = document.getElementById('app-config');
let data = {
    API_BASE_URL: '___failed_to_load__config__',
    MSAL_CONFIG: {
        auth: {
            clientId: '___failed_to_load__config__',
            authority: '___failed_to_load__config__'
        }
    },
    TIMEOUT_MS: 30000,
    VITE_APP_ENV: 'local'
};
try {
    const content = JSON.parse((configEl === null || configEl === void 0 ? void 0 : configEl.innerHTML) || '');
    if (content) {
        data = { ...content };
    }
    else {
        console.error('Failed to parse config');
    }
}
catch (ex) {
    console.error(ex);
}
export default data;
