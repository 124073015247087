import { html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { GuessTableRow } from './guess-table-row';
export default function GuessTableBody({ nonPinnedGuesses, pinnedGuesses, totalGuesses, users, isLoading }) {
    return html `
    <div class="pin-table-body mb-2 ${isLoading ? `opacity-25 pointer-events-none` : ''}">
      ${repeat(pinnedGuesses, (g) => g.name, (guess, i) => {
        // Decorate guess
        const newGuess = guess;
        if (users[guess.name]) {
            guess.users = users[guess.name];
        }
        return GuessTableRow(newGuess, i, totalGuesses, true);
    })}
    </div>
    <div class="pin-table-body ${isLoading ? `opacity-25 pointer-events-none` : ''}">
      ${repeat(nonPinnedGuesses, (g) => g.name, (guess, i) => {
        // Decorate guess
        const newGuess = guess;
        if (users[guess.name]) {
            guess.users = users[guess.name];
        }
        return GuessTableRow(newGuess, i, totalGuesses, false);
    })}
    </div>
  `;
}
