var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import BaseElement from '../base/base-element';
import LabelledInput from './labelled-input';
import { customElement, property, state } from 'lit/decorators.js';
import { orgEdit, orgCheck } from '@nrk/origo';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
let ViewInput = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ViewInput extends BaseElement {
    constructor() {
        super(...arguments);
        this.label = '';
        this.value = '';
        this.path = '';
        this.editMode = false;
    }
    toggleEditMode(e) {
        e.preventDefault();
        this.editMode = !this.editMode;
    }
    render() {
        const alignment = this.editMode ? 'items-end' : 'items-center';
        return html `
      <div class="flex flex-row ${alignment}">
        ${this.editMode
            ? html `
              ${LabelledInput({
                label: this.label,
                value: this.value,
                path: this.path
            })}
            `
            : html ` ${this.value ? this.value : this.label} `}
        <button class="cursor-pointer bg-transparent" @click="${this.toggleEditMode}">
          ${this.editMode ? unsafeHTML(orgCheck) : unsafeHTML(orgEdit)}
        </button>
      </div>
    `;
    }
};
__decorate([
    property({ type: String })
], ViewInput.prototype, "label", void 0);
__decorate([
    property({ type: String })
], ViewInput.prototype, "value", void 0);
__decorate([
    property({ type: String })
], ViewInput.prototype, "path", void 0);
__decorate([
    state()
], ViewInput.prototype, "editMode", void 0);
ViewInput = __decorate([
    customElement('pin-view-input')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ViewInput);
