import { orgCheck, orgClose, orgEdit, orgInfo, orgPin, orgPlayback } from '@nrk/origo';
import { html } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import LabelledInput from '../form/labelled-input';
export function GuessTableRow(guess, i, totalGuesses, isPinned = false) {
    const isGroup = 'guessedNames' in guess;
    const guessNameStyling = isGroup ? 'mr-2 text-lg font-bold' : 'mr-2';
    const pinStyling = isPinned
        ? 'padding: 0; outline: none; color: #0773fb;'
        : 'padding: 0; outline: none;';
    const expandGroupId = `expand-${guess.name}`;
    return html `
    <div class="pin-table-row flex-wrap items-start ${i % 2 ? 'bg-gray-300' : 'bg-white'}">
      <div class="pin-table-cell" style="width: 30px; padding: 7px 10px;">
        <button
          class="pin-button pin-button--transparent"
          style="${pinStyling}"
          type="button"
          js-pin-guess="${guess.name}"
        >
          <div>${unsafeHTML(orgPin)}</div>
          <div class="sr-only">Fest gjett</div>
        </button>
      </div>
      <div class="pin-table-cell" style="width: 30px; padding: 7px 10px;">
        ${isGroup
        ? html `
              <button
                class="pin-button pin-button--transparent "
                style="padding: 0; outline:none;"
                for="${expandGroupId}"
              >
                <div class="sr-only">Ekspander gruppe</div>
              </button>
            `
        : ''}
      </div>
      <div class="pin-table-cell" style="width: 50px; padding: 5px 10px;">
        <input
          type="checkbox"
          name="guess-names"
          style="width: 20px; height: 20px"
          value="${guess.name}"
        />
      </div>
      <div class="pin-table-cell" style="width: 84px;">${guess.count}</div>
      <div class="pin-table-cell flex-1 flex flex-col">
        <div class="flex items-center">
          <div class="${guessNameStyling} flex items-center relative">
            ${isGroup
        ? html `
                  <button
                    class="pin-button pin-button--transparent mr-2"
                    style="padding: 0;"
                    aria-label="${`Slett gruppen ${guess.name}`}"
                    js-remove-group="${guess.name}"
                  >
                    ${unsafeHTML(orgClose)}
                  </button>
                `
        : html ` <div class="inline-block" style="width: 26px"></div> `}
            ${guess.name}
          </div>
          ${isGroup
        ? html `
                <button class="pin-button pin-button--transparent p-0 text-xs">
                  ${unsafeHTML(orgEdit)}
                </button>
                <pin-dropdown class="pin-dropdown" hidden js-dropdown-group>
                  ${LabelledInput({
            label: 'Endre navn på gruppen',
            hint: 'Dette kan bli synlig for publikum',
            name: `edit-${guess.name}`,
            value: guess.name
        })}

                  <div class="mt-8 flex flex-row">
                    <button class="pin-button pin-button--transparent mr-2" js-close-dropdown>
                      Avbryt
                    </button>
                    <button class="pin-button pin-button--primary" js-edit-group="${guess.name}">
                      Bekreft navn
                    </button>
                  </div>
                </pin-dropdown>
              `
        : ''}
        </div>
        ${isGroup
        ? html `
              <pin-expand
                hidden
                class="w-full ${i % 2 ? 'bg-gray-300' : 'bg-white'}"
                id="${expandGroupId}"
              >
                ${repeat(guess.guessedNames || [], (g) => g, (g, i) => html `
                    <div class="mb-2 ${i === 0 ? 'mt-2' : ''} relative">
                      ${guess.name !== g
            ? html `
                            <button
                              class="pin-button pin-button--transparent mr-1"
                              style="padding: 0;"
                              aria-label="${`Fjern ${g} fra gruppen`}"
                              js-remove-name="${g}"
                              js-group="${guess.name}"
                            >
                              ${unsafeHTML(orgClose)}
                            </button>
                          `
            : ''}
                      ${g}
                    </div>
                  `)}
              </pin-expand>
            `
        : ''}
      </div>
      <div class="pin-table-cell text-center" style="width: 135px;">
        ${((guess.count / totalGuesses) * 100).toFixed(1)}%
      </div>
      <div class="pin-table-cell text-center" style="width: 115px;">
        ${guess.correct ? unsafeHTML(orgCheck) : ''}
      </div>
      <button
        class="pin-table-cell bg-transparent text-gray-800 outline-none cursor-pointer flex items-center"
        style="width: 175px;"
      >
        <div class="flex items-center">
          <span>Vis tilfeldige brukere</span>
        </div>
      </button>
      <pin-expand
        hidden
        class="w-full ${i % 2 ? 'bg-gray-300' : 'bg-white'}"
        js-guess-name="${guess.name}"
      >
        <div class="flex justify-end items-start">
          <div class="flex-1 flex items-start bg-blue-600 p-2 text-white">
            <div class="mr-2">${unsafeHTML(orgInfo)}</div>
            Husk at e-post og navn er dekket av personvern. E-post må aldri være synlig for
            publikum.
          </div>
          <div class="px-8 py-4">
            ${guess.users
        ? guess.users.map((user) => {
            return html `
                    <div class="mb-2">
                      <div class="pin-text-label">${user.firstName}</div>
                      <div>${user.email}</div>
                      <div>${user.communeName} - ${user.countyName}</div>
                    </div>
                  `;
        })
        : ''}
            <button
              class="pin-button pin-button--secondary"
              js-fetch-users
              js-guess-name="${guess.name}"
            >
              ${unsafeHTML(orgPlayback)} Last nye
            </button>
          </div>
        </div>
      </pin-expand>
    </div>
  `;
}
