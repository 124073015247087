var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { orgChevronOpen } from '@nrk/origo';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import api from '../api';
import BaseElement from '../components/base/base-element';
import LoaderInline from '../components/loader-inline';
import Masthead from '../components/masthead/masthead';
let RescuedParticipantPage = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RescuedParticipantPage extends BaseElement {
    constructor() {
        super();
        this.isDeletingRescuedParticipant = false;
        this.participant = null;
        this.deleteRescuedParticipant = async () => {
            this.isDeletingRescuedParticipant = true;
            try {
                await api.participants.deleteRescued();
                this.fetchParticipants();
                location.href = '/';
            }
            catch (_error) {
                document.body.insertAdjacentHTML('beforeend', `
          <pin-notification
            class="bg-red-500 text-black"
            type="error"
            dismissable
          >
            Sletting feilet.
          </pin-notification>
        `);
            }
            finally {
                this.isDeletingRescuedParticipant = false;
            }
        };
        this.fetchParticipants = this.fetchParticipants.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.fetchParticipants();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    closeDropdown(e) {
        const dropdown = e.target.closest('pin-dropdown');
        if (dropdown) {
            dropdown.hidden = true;
        }
    }
    fetchParticipants() {
        api.participants.getAll().then((participants) => {
            const participant = participants.find((p) => p.rescued);
            this.participant = participant;
            this.requestUpdate();
        });
    }
    render() {
        var _a;
        if (this.participant == null) {
            return Masthead();
        }
        const mainImage = this.participant.mediaSet && this.participant.mediaSet.find((ms) => ms.type === 'list_image');
        if ((_a = this.participant) === null || _a === void 0 ? void 0 : _a.rescued) {
            return html `
        ${Masthead()}
        <div class="flex items-center flex-col mx-auto mt-8 px-4 max-w-8xl">
          <div class="flex justify-center items-center">
              ${mainImage
                ? html `
                      <img
                        src="${mainImage.href}"
                        width="80"
                        height="80"
                        style="object-fit: cover"
                      />
                    `
                : ''}

              <div class="mx-4">${this.participant.maskName} er markert som reddet</div>
              <img width="32" height="32" src="static/smallgong.png" />
            </div>
            <button
              class="mt-8 mb-8 flex  justify-center items-center pin-button pin-button--error"
              ?disabled="${this.isDeletingRescuedParticipant}"
            >
              ${this.isDeletingRescuedParticipant
                ? html `
                      ${LoaderInline()}
                      <span class="block ml-2">Sletter ...</span>
                    `
                : html `
                      <span class="mr-2">Slett den reddede deltakeren</span>
                      ${unsafeHTML(orgChevronOpen)}
                    `}
            </button>
            <pin-dropdown class="pin-dropdown" js-dropdown-reveal hidden>
              <div class="mb-4">Du er i ferd med å slette en reddet deltaker.</div>
              <div class="mb-4">Dette vil bli synlig for alle brukerne!</div>

              <div class="mt-8 flex flex-row items-center">
                <button
                  class="pin-button pin-button--transparent mr-2"
                  @click="${this.closeDropdown}"
                >
                  Avbryt
                </button>
                <button
                  class="pin-button pin-button--critical flex flex-row items-center"
                  @click="${this.deleteRescuedParticipant}"
                  ?disabled="${this.isDeletingRescuedParticipant}"
                >
                  ${this.isDeletingRescuedParticipant
                ? html `
                          ${LoaderInline()}
                          <span class="block ml-2">Sletter ...</span>
                        `
                : html ` <span class="block ml-2">Slett</span> `}
                </button>
              </div>
            </pin-dropdown>
          </div>
        </div>
      `;
        }
    }
};
__decorate([
    state()
], RescuedParticipantPage.prototype, "isDeletingRescuedParticipant", void 0);
__decorate([
    state()
], RescuedParticipantPage.prototype, "participant", void 0);
RescuedParticipantPage = __decorate([
    customElement('pin-rescuedparticipant-page')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RescuedParticipantPage);
