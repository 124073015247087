import api from '../../api';
// 1. Find checked elements
// 2. Determine which groups the elements belong to (pinned or normal)
// 3. Check if the new group name matches any of the checked elements
// 4. Create the group object with all the guessed names
// 5. Send API request
// 6. If
//    a. the new group is a pinned guess, append all new names
//    b. a pinned guess is added to a normal one, remove the pin
//    c. if a pinned guess is added to another pinned guess, do a. and b.
export function getCheckedGuessElements(element) {
    return [...element.querySelectorAll('[name="guess-names"]')].filter((x) => x.checked);
}
export function getCheckedGuesses(guesses, checkedElements) {
    return guesses.filter((guess) => checkedElements.some((element) => element.value === guess.name));
}
export function getGroupingInformation({ groupName, checkedPinnedGuesses }) {
    const isNewGroupPinned = checkedPinnedGuesses.some((guess) => guess.name === groupName);
    const movePinnedGuessToNonPinnedGroup = !isNewGroupPinned && checkedPinnedGuesses.length > 0;
    return { isNewGroupPinned, movePinnedGuessToNonPinnedGroup };
}
export function createNewGroup({ groupName, participantId, checkedGuesses, checkedPinnedGuesses }) {
    const guessedNames = [...checkedGuesses, ...checkedPinnedGuesses].reduce((acc, guess) => {
        if (guess.guessedNames) {
            guess.guessedNames.forEach((name) => acc.push(name));
        }
        else {
            acc.push(guess.name);
        }
        return acc;
    }, []);
    return {
        groupName,
        participantId,
        guessedNames
    };
}
export function updatePinnedGuesses(pinnedGuesses, id) {
    return Promise.all(pinnedGuesses.map((guess) => api.participants.search({ id, search_starts_with: guess.name, limit: 500 }))).then((data) => {
        const updatedGuesses = data.reduce((acc, d) => {
            acc.push(...d.summary);
            return acc;
        }, []);
        return pinnedGuesses.reduce((acc, guess) => {
            const updatedGuess = updatedGuesses.find((updatedGuess) => updatedGuess.name === guess.name);
            if (updatedGuess) {
                acc.push(updatedGuess);
            }
            else {
                acc.push(guess);
            }
            return acc;
        }, []);
    });
}
export function removePinnedGuesses({ pinnedGuesses, checkedPinnedGuesses }) {
    return pinnedGuesses.filter((guess) => !checkedPinnedGuesses.some((checkedGuess) => checkedGuess.name === guess.name));
}
