var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import BaseElement from '../base/base-element';
import { sessionStorage } from '../../utils/storage';
import { baseChecklist } from './checklist';
import { UPDATE_CHECKLIST } from '../../events';
import { off, on } from '../../utils/events';
let StartRoundChecklist = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class StartRoundChecklist extends BaseElement {
    constructor() {
        super();
        this.checklist = [];
        this.onChange = this.onChange.bind(this);
        this.readStorageStatus = this.readStorageStatus.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.readStorageStatus();
        on(UPDATE_CHECKLIST, this.readStorageStatus);
    }
    disconnectedCallback() {
        off(UPDATE_CHECKLIST, this.readStorageStatus);
    }
    readStorageStatus() {
        this.checklist = baseChecklist.map((checkItem) => {
            const storedChecked = sessionStorage.get(checkItem.id);
            return { ...checkItem, checked: storedChecked === true };
        });
        this.requestUpdate();
    }
    onChange(e) {
        e.preventDefault();
        const checklist = this.checklist;
        const checkitem = checklist.find((item) => { var _a; return ((_a = e.target) === null || _a === void 0 ? void 0 : _a.value) === item.id; });
        if (checkitem != null) {
            checkitem.checked = !checkitem.checked;
            sessionStorage.set(checkitem.id, checkitem.checked);
        }
        this.requestUpdate();
    }
    render() {
        return html `<fieldset class="flex flex-col content-start border-4 border-sky-500 p-2 mt-4">
      <legend><strong>Huskeliste</strong></legend>
      ${this.checklist.map((checkitem) => {
            return html ` <label>
          <input
            type="checkbox"
            id="${checkitem.id}"
            name="todos"
            value="${checkitem.id}"
            .checked=${checkitem.checked}
            @change="${this.onChange}"
          />
          ${checkitem.text}
        </label>`;
        })}
    </fieldset>`;
    }
};
__decorate([
    state()
], StartRoundChecklist.prototype, "checklist", void 0);
StartRoundChecklist = __decorate([
    customElement('pin-start-round-checklist')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], StartRoundChecklist);
