var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import BaseElement from '../components/base/base-element';
import Masthead from '../components/masthead/masthead';
import api from '../api';
import { on, off } from '../utils/events';
import { PARTICIPANT_REVEAL, PARTICIPANT_REVERT_REVEAL, PARTICIPANT_CHANGE_REVEAL, PARTICIPANT_RESCUE } from '../events';
let ParticipantPage = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantPage extends BaseElement {
    constructor() {
        super();
        this.participantId = -1;
        this.groupName = '';
        this.currentPage = 0;
        this.isPaused = false;
        this.isRevealingParticipant = false;
        this.isRescuingParticipant = false;
        this.isEditingRevealedParticipant = false;
        this.participant = null;
        this.round = null;
        this.pauseTimer = null;
        this.revealParticipant = async (name) => {
            if (name && name.length > 0) {
                this.isRevealingParticipant = true;
                try {
                    await api.participants.reveal({
                        ...this.participant,
                        realName: name
                    });
                    this.fetchParticipant();
                }
                catch (_error) {
                    document.body.insertAdjacentHTML('beforeend', `
          <pin-notification
            class="bg-red-500 text-black"
            type="error"
            dismissable
          >
            Avsløring feilet.
          </pin-notification>
        `);
                }
                finally {
                    this.isRevealingParticipant = false;
                }
            }
        };
        this.rescueParticipant = async () => {
            this.isRescuingParticipant = true;
            try {
                await api.participants.rescue(this.participant);
                this.fetchParticipant();
                location.href = '/';
            }
            catch (_error) {
                document.body.insertAdjacentHTML('beforeend', `
          <pin-notification
            class="bg-red-500 text-black"
            type="error"
            dismissable
          >
            Redding feilet.
          </pin-notification>
        `);
            }
            finally {
                this.isRescuingParticipant = false;
            }
        };
        this.fetchParticipant = this.fetchParticipant.bind(this);
        this.revertReveal = this.revertReveal.bind(this);
        this.changeRevealParticipant = this.changeRevealParticipant.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        on(PARTICIPANT_REVEAL, this.revealParticipant);
        on(PARTICIPANT_RESCUE, this.rescueParticipant);
        on(PARTICIPANT_REVERT_REVEAL, this.revertReveal);
        on(PARTICIPANT_CHANGE_REVEAL, this.revealParticipant);
        this.fetchParticipant();
        this.fetchRound();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        off(PARTICIPANT_REVEAL, this.revealParticipant);
        off(PARTICIPANT_RESCUE, this.rescueParticipant);
        off(PARTICIPANT_REVERT_REVEAL, this.revertReveal);
        off(PARTICIPANT_CHANGE_REVEAL, this.revealParticipant);
    }
    fetchParticipant() {
        api.participants.get(this.participantId).then((participant) => {
            this.participant = participant;
            this.isEditingRevealedParticipant = false;
            this.requestUpdate();
        });
    }
    changeRevealParticipant(name) {
        if (name && name.length > 0) {
            this.isEditingRevealedParticipant = true;
            api.participants
                .reveal({
                ...this.participant,
                realName: name
            })
                .then(() => {
                this.fetchParticipant();
            });
        }
    }
    revertReveal() {
        this.isRevealingParticipant = true;
        api.participants
            .removeReveal({
            ...this.participant,
            realName: null
        })
            .then(() => {
            this.fetchParticipant();
        });
    }
    fetchRound() {
        api.rounds.getRound().then((round) => {
            this.round = round;
            if (this.round.endTime !== null) {
                const now = Date.now();
                const pauseTime = new Date(this.round.endTime);
                const diff = pauseTime.getTime() - now;
                if (this.pauseTimer !== null) {
                    clearTimeout(this.pauseTimer);
                }
                if (diff > 0) {
                    this.pauseTimer = window.setTimeout(() => {
                        this.isPaused = true;
                        this.requestUpdate();
                    }, diff);
                }
                else {
                    this.isPaused = true;
                    this.requestUpdate();
                }
            }
            else {
                this.isPaused = false;
                this.round = null;
                this.requestUpdate();
            }
        });
    }
    render() {
        return html `
      ${Masthead()}
      <pin-participant-header
        .participant="${this.participant}"
        .bgColor="${this.isPaused ? 'bg-yellow-300' : 'bg-green-500'}"
      ></pin-participant-header>
      <div class="block container mx-auto mt-8 px-4 max-w-8xl">
        <div class="mb-10 lg:flex lg:flex-row-reverse lg:items-start">
          <div class="py-5 lg:pt-10 lg:pl-10 lg:w-1/4">
            <pin-participant-sidebar
              .participant="${this.participant}"
              ?isRevealingParticipant="${this.isRevealingParticipant}"
              ?isRescuingParticipant="${this.isRescuingParticipant}"
              ?isPaused="${this.isPaused}"
            ></pin-participant-sidebar>
          </div>
          <div class="mb-10 lg:pt-4 lg:w-3/4">
            <pin-participant-guesses
              .participant="${this.participant}"
              participantid="${this.participantId}"
              ?isPaused="${this.isPaused}"
              ?isRevealingParticipant="${this.isRevealingParticipant}"
              ?isEditingRevealedParticipant="${this.isEditingRevealedParticipant}"
            ></pin-participant-guesses>
          </div>
        </div>
      </div>
    `;
    }
};
__decorate([
    property({ type: Number })
], ParticipantPage.prototype, "participantId", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "groupName", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "currentPage", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "isPaused", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "isRevealingParticipant", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "isRescuingParticipant", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "isEditingRevealedParticipant", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "participant", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "round", void 0);
__decorate([
    state()
], ParticipantPage.prototype, "pauseTimer", void 0);
ParticipantPage = __decorate([
    customElement('pin-participant-page')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantPage);
