import { orgSortAsc, orgSortDesc } from '@nrk/origo';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
export function GuessTableHeader(sortDirection, sortType) {
    return html `
    <div class="pin-table-header pin-table-row">
      <div class="pin-table-cell" style="width: 30px"></div>
      <div class="pin-table-cell" style="width: 30px"></div>
      <div class="pin-table-cell" style="width: 50px">
        <input
          type="checkbox"
          name="all-guess-names"
          style="width: 20px; height: 20px"
          js-check-all="whatever"
        />
      </div>
      <button
        class="pin-table-cell bg-transparent text-gray-800 outline-none cursor-pointer uppercase"
        aria-label="Sorter listen basert på antall forslag"
        style="width: 84px;"
        js-sort
        js-sort-type="count"
        js-sort-direction="${sortDirection === 'asc' ? 'desc' : 'asc'}"
      >
        <div class="flex items-center">
          <span>Antall</span>
          ${sortType === 'count'
        ? html ` ${sortDirection === 'asc' ? unsafeHTML(orgSortAsc) : unsafeHTML(orgSortDesc)} `
        : ''}
        </div>
      </button>
      <div class="pin-table-cell flex items-center flex-1 text-gray-800">
        <div style="width: 24px;"></div>
        <div>Forslag</div>
      </div>
      <button
        class="pin-table-cell bg-transparent text-gray-800 outline-none cursor-pointer uppercase"
        aria-label="Sorter listen basert på antall forslag i prosent"
        style="width: 135px;"
        js-sort
        js-sort-type="count"
        js-sort-direction="${sortDirection === 'asc' ? 'desc' : 'asc'}"
      >
        <div class="flex items-center justify-center">
          <span>Prosentandel</span>
          ${sortType === 'count'
        ? html ` ${sortDirection === 'asc' ? unsafeHTML(orgSortAsc) : unsafeHTML(orgSortDesc)} `
        : ''}
        </div>
      </button>
      <button
        class="pin-table-cell bg-transparent text-gray-800 outline-none cursor-pointer uppercase"
        aria-label="Sorter listen basert på riktige svar"
        style="width: 115px;"
        js-sort
        js-sort-type="correct"
        js-sort-direction="${sortDirection === 'asc' ? 'desc' : 'asc'}"
      >
        <div class="flex items-center justify-center">
          <span>Riktig svar</span>
          ${sortType === 'correct'
        ? html ` ${sortDirection === 'asc' ? unsafeHTML(orgSortAsc) : unsafeHTML(orgSortDesc)} `
        : ''}
        </div>
      </button>
      <div class="pin-table-cell flex items-center text-gray-800" style="width: 175px;">
        Brukere
      </div>
    </div>
  `;
}
