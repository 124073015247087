import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import config from '../config/config';
const { MSAL_CONFIG } = config;
const APP_SCOPES = ['api://maskorama/Read', 'api://maskorama/Write'];
let msalInstance;
const authConfig = {
    redirectUri: window.location.origin
};
export const initializeAuth = async () => {
    msalInstance = await PublicClientApplication.createPublicClientApplication({
        auth: {
            ...MSAL_CONFIG.auth,
            ...authConfig
        }
    });
    const redirectResult = await msalInstance.handleRedirectPromise();
    console.log(redirectResult);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
    else {
        return;
    }
    await getAndFreshifyToken();
};
const getAndFreshifyToken = async () => {
    const currentAccount = msalInstance.getActiveAccount();
    if (currentAccount == null) {
        // await msalInstance.loginRedirect();
        return;
    }
    const silentRequest = {
        scopes: APP_SCOPES,
        account: currentAccount,
        forceRefresh: false
    };
    try {
        return (await msalInstance.acquireTokenSilent(silentRequest)).accessToken;
    }
    catch (error) {
        console.error('Silent token acquisition fails. Acquiring token using redirect');
        if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect({
                scopes: APP_SCOPES
            });
        }
    }
};
export const auth = {
    getAccessToken: async () => {
        return await getAndFreshifyToken();
    },
    getUserName: () => {
        var _a;
        return (_a = msalInstance.getActiveAccount()) === null || _a === void 0 ? void 0 : _a.name;
    },
    isLoggedIn: () => {
        const account = msalInstance.getActiveAccount();
        if (account) {
            return true;
        }
        msalInstance.loginRedirect({
            scopes: APP_SCOPES
        });
    },
    logout: () => {
        msalInstance.logout();
    }
};
