var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { orgInfo } from '@nrk/origo';
import LoaderInline from '../loader-inline';
import LabelledInput from '../form/labelled-input';
import BaseElement from '../base/base-element';
import { customElement, property } from 'lit/decorators.js';
import { trigger } from '../../utils/events';
import { PARTICIPANT_CHANGE_REVEAL, PARTICIPANT_REVERT_REVEAL } from '../../events';
let PRA = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class PRA extends BaseElement {
    constructor() {
        super(...arguments);
        this.isRevealingParticipant = false;
        this.isEditingRevealedParticipant = false;
    }
    closeDropdown(e) {
        const dropdown = e.target.closest('pin-dropdown');
        if (dropdown) {
            dropdown.hidden = true;
        }
    }
    revertReveal() {
        trigger(PARTICIPANT_REVERT_REVEAL);
    }
    revealParticipant(e) {
        const name = this.querySelector("[name='reveal-participant']").value;
        trigger(PARTICIPANT_CHANGE_REVEAL, name);
        this.closeDropdown(e);
    }
    render() {
        return html `
      <div class="bg-blue-600 text-white p-2 flex flex-row items-center mb-2">
        ${unsafeHTML(orgInfo)}
        <div class="mx-2">
          Denne deltakeren er satt som avslørt og vil avsløres når pause skrus av
        </div>
        <button
          class="pin-button pin-button--primary flex flex-row items-center"
          @click="${this.revertReveal}"
          ?disabled="${this.isRevealingParticipant || this.isEditingRevealedParticipant}"
        >
          ${this.isRevealingParticipant
            ? html `
                ${LoaderInline()}
                <span class="block ml-2">Angrer ...</span>
              `
            : html ` <span class="block">Angre</span> `}
        </button>
      </div>
      <div class="bg-blue-600 text-white p-2 flex flex-row items-center">
        ${unsafeHTML(orgInfo)}
        <div class="mx-2">Artisten avsløres som "${this.participant.realName}".</div>
        <button
          class="pin-button pin-button--primary flex flex-row items-center"
          ?disabled="${this.isRevealingParticipant || this.isEditingRevealedParticipant}"
        >
          ${this.isEditingRevealedParticipant
            ? html `
                ${LoaderInline()}
                <span class="block ml-2">Endrer ...</span>
              `
            : html ` <span class="block">Endre</span> `}
        </button>
        <pin-dropdown class="pin-dropdown" js-dropdown-reveal hidden>
          <div class="mb-4">Du er i ferd med å endre det avslørte navnet på deltakeren.</div>
          <div class="mb-4">Dette vil bli synlig for alle brukerne!</div>

          ${LabelledInput({
            label: 'Skriv deltakerens identitet',
            name: 'reveal-participant'
        })}
          <div class="mt-4 flex flex-row items-center">
            <button class="pin-button pin-button--transparent mr-2" @click="${this.closeDropdown}">
              Avbryt
            </button>
            <button class="pin-button pin-button--critical" @click="${this.revealParticipant}">
              Avslør
            </button>
          </div>
        </pin-dropdown>
      </div>
    `;
    }
};
__decorate([
    property({ type: Object })
], PRA.prototype, "participant", void 0);
__decorate([
    property({ type: Boolean })
], PRA.prototype, "isRevealingParticipant", void 0);
__decorate([
    property({ type: Boolean })
], PRA.prototype, "isEditingRevealedParticipant", void 0);
PRA = __decorate([
    customElement('pin-participant-revealed-actions')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], PRA);
