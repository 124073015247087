const fetchTimer = (url, request = {}, timeout = 2500) => {
    return new Promise((resolve, reject) => {
        const controller = new AbortController();
        const signal = controller.signal;
        const timer = setTimeout(() => {
            controller.abort();
            reject(new Error('Request timed out'));
        }, timeout);
        fetch(url, Object.assign({}, request, { signal }))
            .then((response) => {
            resolve(response);
        })
            .catch((error) => reject(error))
            .finally(() => {
            clearTimeout(timer);
        });
    });
};
export default fetchTimer;
