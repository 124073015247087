import BaseElement from '../base/base-element';
import { trigger } from '../../utils/events';
import { DRAG_AND_DROP_REORDER } from '../../events';

class DragAndDrop extends BaseElement {
  static get properties() {
    return {
      canDrag: { type: Boolean }
    };
  }

  constructor() {
    super();

    this.onDragStart = this.onDragStart.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragOver = this.onDragOver.bind(this);

    this.dragEl = null;
    this.canDrag = true;
  }

  connectedCallback() {
    const children = Array.prototype.slice.call(this.children);
    this.dataType = this.getAttribute('data-type');
    this.realChildren = children;

    this.addEventListener('dragstart', this.onDragStart, false);
    this.addEventListener('dragend', this.onDragEnd, false);
    this.addEventListener('drop', this.onDrop, false);
    this.addEventListener('dragenter', this.onDragEnter, false);
    this.addEventListener('dragleave', this.onDragLeave, false);
    this.addEventListener('dragover', this.onDragOver, false);
  }

  disconnectedCallback() {
    this.removeEventListener('dragstart', this.onDragStart);
    this.removeEventListener('dragend', this.onDragEnd);
    this.removeEventListener('drop', this.onDrop);
    this.removeEventListener('dragenter', this.onDragEnter);
    this.removeEventListener('dragleave', this.onDragLeave);
    this.removeEventListener('dragover', this.onDragOver);
  }

  onDragStart(e) {
    if (!this.canDrag) {
      return;
    }
    let el;
    // If we don't have a target we can't initiate dragging
    if (typeof e.target === 'undefined' || !e.target) {
      return;
    }

    e.dataTransfer.setData('text/plain', null);
    if ((el = e.target?.closest('[draggable]'))) {
      this.dragEl = el;
    }
  }

  onDragEnd(_e) {
    this.dragEl = null;
  }

  onDragOver(e) {
    // prevent default to allow drop
    e.preventDefault();
  }

  onDrop(e) {
    e.preventDefault();

    if (this.dragEl === null) {
      return;
    }

    const els = Array.prototype.slice.call(this.querySelectorAll('pin-droppable'));

    const index = els.indexOf(e.target);
    const oldIndex = els.indexOf(this.dragEl.previousElementSibling);

    if (index !== -1 && oldIndex !== -1) {
      trigger(DRAG_AND_DROP_REORDER, {
        dataType: this.dataType,
        el: this.dragEl,
        to: index,
        from: oldIndex
      });
    }

    e.target.classList.remove('pin-droppable-over');
    this.dragEl = null;
  }

  onDragEnter(e) {
    if (this.canDrag && e.target?.classList?.contains('pin-droppable')) {
      e.target.classList.add('pin-droppable-over');
    }
  }

  onDragLeave(e) {
    if (this.canDrag && e.target?.classList?.contains('pin-droppable')) {
      e.target.classList.remove('pin-droppable-over');
    }
  }
}

if (!window.customElements.get('pin-dnd')) {
  window.customElements.define('pin-dnd', DragAndDrop);
}
