import config from '../config/config';
import { auth } from '../utils/auth';
import fetchTimer from './fetch-timer';
async function doFetch(method, url, body = null) {
    const opts = {
        withCredentials: true,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await auth.getAccessToken()}`
        },
        method: method,
        body: body
    };
    return fetchTimer(url, opts, config.TIMEOUT_MS)
        .then((res) => {
        if (res.status === 204) {
            return Promise.resolve();
        }
        else if (res.ok && res.json) {
            return res.json();
        }
        else if (res.ok) {
            return Promise.resolve();
        }
        else {
            return Promise.reject(res);
        }
    })
        .catch(async (err) => {
        if (err.json) {
            await err.json().then((data) => {
                throw new Error(`${err.status} ${err.statusText}: ${data.message}`);
            });
        }
        else if (err.message.includes('Request timed out')) {
            throw new Error(err.message);
        }
        else {
            throw new Error(`${err.status} ${err.statusText}`);
        }
    });
}
export default (baseUrl) => {
    return {
        get: async (path) => {
            return doFetch('GET', baseUrl + path);
        },
        put: async (path, data) => {
            return doFetch('PUT', baseUrl + path, JSON.stringify(data));
        },
        post: async (path, data) => {
            return doFetch('POST', baseUrl + path, JSON.stringify(data));
        },
        patch: async (path, data) => {
            return doFetch('PATCH', baseUrl + path, JSON.stringify(data));
        },
        delete: async (path, data) => {
            return doFetch('DELETE', baseUrl + path, JSON.stringify(data));
        }
    };
};
