import { orgCheck, orgFavoriteOn } from '@nrk/origo';
import { html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
export default function ParticipantItem(participant, allowDrag = false, fewestFavorites = false) {
    const mainImage = participant.mediaSet && participant.mediaSet.find((ms) => ms.type === 'list_image');
    const isRevealed = participant.realName && participant.realName.length > 0;
    return html `
    <div class="flex items-center">
      ${allowDrag
        ? html `
            <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
              <path
                d="M5 0H3V2H5V0ZM9 0H7V2H9V0ZM3 5H5V7H3V5ZM9 5H7V7H9V5ZM3 10H5V12H3V10ZM9 10H7V12H9V10Z"
              />
            </svg>
          `
        : html ` <div class="w-4"></div> `}
      ${participant.rescued
        ? html `<div class="ml-4 w-full">
            <pin-tile ?isrescued="${participant.rescued}">
              ${mainImage
            ? html `
                    <img src="${mainImage.href}" width="80" height="80" style="object-fit: cover" />
                  `
            : ''}

              <div class="flex-1 mx-4">${participant.maskName}</div>
              <div class="mx-4 flex items-center justify-start" style="width: 90px;">
                <span class="ml-2">-</span>
              </div>
              <div class="mx-4 flex items-center justify-start" style="width: 75px;">
                <span class="ml-2">-</span>
              </div>
              <div class="mx-4 flex items-center justify-start" style="width: 80px;">
                ${participant.rescued
            ? html `<img width="48" height="48" src="static/smallgong.png" />`
            : ''}
              </div>
            </pin-tile>
          </div>`
        : html ` <a class="no-underline ml-4 w-full" href="/participants/${participant.id}">
            <pin-tile ?isrevealed="${isRevealed}" ?fewestFavorites="${fewestFavorites}">
              ${mainImage
            ? html `
                    <img src="${mainImage.href}" width="80" height="80" style="object-fit: cover" />
                  `
            : ''}

              <div class="flex-1 mx-4">${participant.maskName}</div>
              <div class="mx-4 flex items-center justify-start" style="width: 90px;">
                ${unsafeHTML(orgFavoriteOn)}
                <span class="ml-2">${participant.favoriteCount}</span>
              </div>
              <div class="mx-4 flex items-center justify-start" style="width: 75px;">
                <span class="ml-2">${participant.guessCount}</span>
              </div>
              <div class="mx-4 flex items-center justify-start" style="width: 80px;">
                ${isRevealed ? html ` ${unsafeHTML(orgCheck)} ` : ''}
              </div>
            </pin-tile>
          </a>`}
    </div>
  `;
}
