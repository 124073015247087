import kaleido from '@nrk/kaleido-ui';
// import OrigoDialog from "@nrk/origo-dialog";
import CoreDialog from '@nrk/core-dialog/core-dialog.cjs';
import OrigoDatepicker from '@nrk/origo-datepicker';
import OrigoDropdown from '@nrk/origo-dropdown';
import OrigoExpand from '@nrk/origo-expand';
import OrigoNotification from '@nrk/origo-notification';
import './components/init.js';
import applyRouter from './routes/index';
import { auth, initializeAuth } from './utils/auth';
import router from './utils/history';
import { initializeSentry } from './utils/initSentry';

const init = async () => {
  await initializeAuth();

  if (auth.isLoggedIn()) {
    kaleido.initialize({
      username: 'maskorama-admin', // Used to log usage. Example: 'system.userId' or 'kaleido.n000000'
      environment: 'prod' // Switches API to use. Can be 'dev', 'stage' or 'prod'
    });

    initializeSentry();

    // if (!window.customElements.get("origo-dialog")) {
    //   window.customElements.define("origo-dialog", OrigoDialog);
    // }

    if (!window.customElements.get('pin-dialog')) {
      window.customElements.define('pin-dialog', CoreDialog);
    }

    if (!window.customElements.get('pin-notification')) {
      window.customElements.define('pin-notification', OrigoNotification);
    }

    if (!window.customElements.get('pin-datepicker')) {
      window.customElements.define('pin-datepicker', OrigoDatepicker);
    }

    if (!window.customElements.get('pin-dropdown')) {
      window.customElements.define('pin-dropdown', OrigoDropdown);
    }

    if (!window.customElements.get('pin-expand')) {
      window.customElements.define('pin-expand', OrigoExpand);
    }

    applyRouter(router);

    router.resolve();
  }
};

init();
