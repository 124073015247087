import { html } from 'lit';
import GuessActions from './guess-actions';
export default function GuessTableFooter({ currentPage, nextDisabled, previousDisabled, markGroupDisabled, isCreatingGroup, closeDropdown, createGroup, markCorrectDisabled, isMarkingGuess, hasSelectedIncorrectGuess, markGuess, groupName }) {
    return html `
    <div class="mt-8 flex flex-row">
      <div class="flex-1 flex flex-row items-center">
        <pin-participant-pagination
          page="${currentPage}"
          ?nextDisabled="${nextDisabled}"
          ?previousDisabled="${previousDisabled}"
        >
        </pin-participant-pagination>
      </div>
      ${GuessActions({
        isCreatingGroup,
        markGroupDisabled,
        closeDropdown,
        createGroup,
        markCorrectDisabled,
        isMarkingGuess,
        hasSelectedIncorrectGuess,
        markGuess,
        groupName
    })}
    </div>
  `;
}
