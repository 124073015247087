/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { html } from 'lit';
import { live } from 'lit/directives/live.js';
import LoaderInline from '../loader-inline';
export default function GuessActions({ markGroupDisabled, isCreatingGroup, closeDropdown, createGroup, markCorrectDisabled, isMarkingGuess, hasSelectedIncorrectGuess, markGuess, groupName }) {
    return html `
    <button
      class="pin-button pin-button--secondary flex flex-row items-center h-fit"
      ?disabled="${markGroupDisabled || isCreatingGroup}"
    >
      ${isCreatingGroup
        ? html `
            ${LoaderInline()}
            <span class="block ml-2">Slår sammen forslag ...</span>
          `
        : html ` <span>Slå sammen forslag</span> `}
    </button>
    <pin-dropdown class="pin-dropdown" hidden js-dropdown-group>
      <label class="">
        <span class="inline-block font-semibold pin-text-label">Sett navn på gruppen</span>
        <input
          name="group-name"
          type="text"
          .value="${live(groupName)}"
          class="py-2 px-3 border border-gray-300 bg-gray-100 rounded w-full"
          data-lpignore="true"
        />
      </label>

      <div class="mt-8 flex flex-row">
        <button class="pin-button pin-button--transparent mr-2" @click="${closeDropdown}">
          Avbryt
        </button>
        <button
          class="pin-button pin-button--primary flex flex-row items-center"
          ?disabled="${isCreatingGroup}"
          @click="${createGroup}"
        >
          ${isCreatingGroup
        ? html `
                ${LoaderInline()}
                <span class="block ml-2">Slår sammen forslag ...</span>
              `
        : html ` <span>Bekreft navn</span> `}
        </button>
      </div>
    </pin-dropdown>

    <button
      class="ml-2 pin-button pin-button--secondary flex flex-items items-center h-fit"
      ?disabled="${markCorrectDisabled || isMarkingGuess}"
      @click="${markGuess}"
    >
      ${hasSelectedIncorrectGuess
        ? isMarkingGuess
            ? html `
              ${LoaderInline()}
              <span class="block ml-2">Markerer ...</span>
            `
            : html ` <span>Merk som riktig svar</span> `
        : isMarkingGuess
            ? html `
              ${LoaderInline()}
              <span class="block ml-2">Fjerner ...</span>
            `
            : html ` <span>Fjern som riktig svar</span> `}
    </button>
  `;
}
