export default (transport) => {
    return {
        get: async (id) => {
            return transport.get(`/api/admin/participants/${id}`);
        },
        getAll: async () => {
            return await transport.get(`/api/admin/participants`);
        },
        getGuesses: async (id) => {
            return transport.get(`/api/admin/participants/${id}/guesses`);
        },
        create: async (participant) => {
            return transport.post('/api/admin/participants', participant);
        },
        update: async (participant) => {
            return transport.put(`/api/admin/participants/${participant.id}`, participant);
        },
        reveal: async (participant) => {
            return transport.put(`/api/admin/participants/${participant.id}?isReveal=true`, participant);
        },
        rescue: async (participant) => {
            return transport.post(`/api/admin/participants/rescue/${participant.id}`);
        },
        deleteRescued: async () => {
            return transport.delete(`/api/admin/participants/rescue`);
        },
        removeReveal: async (participant) => {
            return transport.put(`/api/admin/participants/${participant.id}`, participant);
        },
        search: async ({ id, search_starts_with = '', search_query = '', limit = 100, skip = 0, desc = false }) => {
            let url = `/api/admin/participants/${id}/guesses?`;
            if (search_starts_with) {
                url += `startsWith=${search_starts_with}&`;
            }
            if (search_query) {
                url += `searchQuery=${search_query}&`;
            }
            if (limit) {
                url += `limit=${limit}&`;
            }
            if (skip) {
                url += `skip=${skip}&`;
            }
            if (typeof desc !== 'undefined') {
                url += `desc=${desc}&`;
            }
            return transport.get(url);
        },
        createGroup: async (groupName, payload) => {
            return transport.put(`/api/admin/groups/${encodeURIComponent(groupName)}`, payload);
        },
        saveParticipantsOrder: async (participants) => {
            const order = participants.map((p) => ({ id: p.id }));
            return transport.patch(`/api/admin/participants`, order);
        },
        getRandomUsersWithGuessedName: async (participantId, guessedName) => {
            return transport.get(`/api/admin/participants/${participantId}/users?guessedname=${encodeURIComponent(guessedName)}&limit=5&random=true`);
        }
    };
};
