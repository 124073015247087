var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import BaseElement from '../base/base-element';
import Masthead from '../masthead/masthead';
import LabelledInput from '../form/labelled-input';
import { immutableUpdate } from '../../utils/obj';
import api from '../../api/';
let ParticipantsNew = class ParticipantsNew extends BaseElement {
    constructor() {
        super();
        this.updateValue = this.updateValue.bind(this);
        this.handleImageSelected = this.handleImageSelected.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('change', this.updateValue);
        this.addEventListener('image-picker-selected', this.handleImageSelected);
        this.addEventListener('image-editor-change', this.handleImageChange);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('image-picker-selected', this.handleImageSelected);
        this.removeEventListener('image-editor-change', this.handleImageChange);
    }
    handleImageSelected(e) {
        console.log('handleImageSelected: ', e, e.detail);
        this.data = {
            ...this.data,
            media: {
                ...this.data.media,
                kaleidoId: e.detail.kaleidoId[0]
            }
        };
        this.requestUpdate();
    }
    handleImageChange(e) {
        console.log('change: ', e.detail);
        this.data = {
            ...this.data,
            media: {
                ...this.data.media,
                params: e.detail.params
            }
        };
    }
    updateValue(e) {
        if (e.target.hasAttribute('js-data-path')) {
            const path = e.target.getAttribute('js-data-path');
            const value = e.target.value;
            const newData = immutableUpdate(this.data, path, value);
            this.data = newData;
            this.requestUpdate();
            console.log('newData: ', this.data);
        }
    }
    onSave(e) {
        e.preventDefault();
        console.log('shall save: ', this.data);
        api.participants.create({
            maskName: this.data.maskName,
            mediaSet: this.data.media
        });
        document.body.insertAdjacentHTML('beforeend', `
      <pin-notification
        class="bg-green-500 text-white"
        type="success"
        dismissable
      >
        Lagringen er vellykket.
      </pin-notification>
    `);
    }
    render() {
        console.log('**** RENDER ****');
        console.log(this.data);
        const extraStyling = this.data.kaleidoId ? '' : 'h-64';
        return html `
      ${Masthead()}
      <div class="block container mx-auto px-4 max-w-6xl">
        <div class="mb-10 lg:flex lg:items-start">
          <div class="my-5 mx-4 lg:w-1/4">
            <div class="bg-gray-400 w-full h-56 flex justify-center items-center">
              Placeholder for film
            </div>

            <div
              class="bg-gray-400 w-full overflow-none flex justify-center items-center mt-4 mb-2 ${extraStyling}"
            >
              <pin-image-editor
                class="flex-1"
                kaleidoId="${this.data.media.kaleidoId}"
              ></pin-image-editor>
            </div>

            <pin-image-picker label="Velg hovedbilde"></pin-image-picker>
          </div>
          <div class="py-5 lg:w-2/4">
            <pin-view-input
              label="Navn på maske"
              value="${ifDefined(this.data.maskName)}"
              path="maskName"
            ></pin-view-input>

            <div class="mt-8">
              ${LabelledInput({
            label: 'Feedback på innsendt forslag',
            hint: 'Skriv inn tilbakemelding - maks 150 tegn',
            value: ''
        })}
            </div>
            <div class="mt-4">
              ${LabelledInput({
            label: 'Feedback på valgt favoritt',
            hint: 'Skriv inn tilbakemelding - maks 150 tegn',
            value: ''
        })}
            </div>
            <div class="mt-4">
              ${LabelledInput({
            label: 'Redaksjonelt hint',
            hint: 'Skriv inn redaksjonelt hint, vises etter 30 sek',
            value: ''
        })}
            </div>
          </div>
        </div>

        <div class="">
          <button
            class="py-2 px-5 rounded font-semibold text-sm disabled:cursor-not-allowed bg-blue-700 text-white disabled:bg-blue-300"
            @click="${this.onSave}"
          >
            Lagre
          </button>
        </div>
      </div>
    `;
    }
};
__decorate([
    state()
], ParticipantsNew.prototype, "data", void 0);
ParticipantsNew = __decorate([
    customElement('pin-participants-new')
], ParticipantsNew);
// Define if not already defined
if (!window.customElements.get('pin-participants-new')) {
    window.customElements.define('pin-participants-new', ParticipantsNew);
}
