var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BaseElement from '../base/base-element';
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { orgChevronClosed } from '@nrk/origo';
import Banner from '../banner/banner';
let ParticipantHeader = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantHeader extends BaseElement {
    constructor() {
        super(...arguments);
        this.participant = null;
        this.bgColor = '';
    }
    render() {
        return html `
      ${Banner(html `
          <div class="py-3 flex items-start">
            <a
              href="/"
              class="mr-5 w-9 h-9 text-gray-600 bg-gray-200 rounded-full flex items-center justify-center hover:bg-gray-700 hover:text-white"
              aria-label="Tilbake til forsiden"
            >
              <div class="transform rotate-180">${unsafeHTML(orgChevronClosed)}</div>
            </a>
            <div>
              <div class="mb-3 flex items-center">
                <h1 class="mr-2 text-4xl leading-none uppercase">
                  ${this.participant ? this.participant.maskName : ''}
                </h1>
                <pin-round-status></pin-round-status>
              </div>
              <p class="text-sm text-gray-700"></p>
            </div>
          </div>
        `, this.bgColor)}
    `;
    }
};
__decorate([
    property({ type: Object })
], ParticipantHeader.prototype, "participant", void 0);
__decorate([
    property({ type: String })
], ParticipantHeader.prototype, "bgColor", void 0);
ParticipantHeader = __decorate([
    customElement('pin-participant-header')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantHeader);
