var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BaseElement from '../base/base-element';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import api from '../../api';
let RoundStatus = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class RoundStatus extends BaseElement {
    constructor() {
        super();
        this.round = null;
        this.pauseTime = null;
        this.pauseText = '';
        this.round = null;
        this.timer = this.timer.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.fetch();
    }
    fetch() {
        api.rounds.getRound().then((round) => {
            this.round = round;
            if (this.round !== null && this.round.endTime !== null) {
                this.pauseTime = new Date(this.round.endTime);
                this.timer();
            }
            else {
                this.round = null;
                this.requestUpdate();
            }
        });
    }
    timer() {
        var _a;
        const now = Date.now();
        const diff = (((_a = this.pauseTime) === null || _a === void 0 ? void 0 : _a.getTime()) || now) - now;
        if (diff > 0) {
            let seconds = diff / 1000;
            const minutes = Math.floor(seconds / 60);
            seconds = Math.floor(seconds - minutes * 60);
            if (seconds < 10) {
                seconds = `0${seconds}`;
            }
            this.pauseText = `0${minutes}:${seconds} til pause`;
            window.setTimeout(this.timer, 1000);
        }
        else {
            this.pauseText = 'Avstemning satt på pause';
        }
        this.requestUpdate();
    }
    render() {
        if (this.round === null) {
            return html ``;
        }
        return html ` <div class="pin-status-pill">${this.pauseText}</div> `;
    }
};
__decorate([
    state()
], RoundStatus.prototype, "round", void 0);
__decorate([
    state()
], RoundStatus.prototype, "pauseTime", void 0);
__decorate([
    state()
], RoundStatus.prototype, "pauseText", void 0);
RoundStatus = __decorate([
    customElement('pin-round-status')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], RoundStatus);
