import kaleido from '@nrk/kaleido-ui';
import { LitElement, html } from 'lit';

export default class ImageEditor extends LitElement {
  static get properties() {
    return {
      kaleidoId: { type: String },
      format: { type: String },
      params: { type: Object },
      imgid: { type: String }
    };
  }

  constructor() {
    super();
    this.format = '1:1';
    this.params = {};
    this.imgid = Math.random();
  }

  connectedCallback() {
    super.connectedCallback();
    const imageEditorEl = this.querySelector('[js-image-editor]');

    if (imageEditorEl) {
      console.log('setting up');
      kaleido.editor(imageEditorEl, {
        format: this.format,
        params: this.params,
        onChange: (state) => {
          console.log('state aCC: ', state);
          this.dispatchEvent(
            new CustomEvent('image-editor-change', {
              bubbles: true,
              detail: {
                imgid: this.imgid,
                params: state.params
              }
            })
          );
        }
      });
    }
  }

  attributeChangedCallback(name, oldVal, newVal) {
    super.attributeChangedCallback(name, oldVal, newVal);
    if (name === 'kaleidoid' && newVal !== 'undefined' && newVal !== null) {
      const imageEditorEl = this.querySelector('[js-image-editor]');
      if (imageEditorEl) {
        console.log('imageEditorEl: ', imageEditorEl, this.params);
        kaleido.editor(imageEditorEl, {
          id: newVal,
          format: this.format,
          params: this.params,
          onChange: (state) => {
            console.log('state aCC: ', state);
            this.dispatchEvent(
              new CustomEvent('image-editor-change', {
                bubbles: true,
                detail: {
                  imgid: this.imgid,
                  params: state.params
                }
              })
            );
          }
        });
      }
    }
  }

  createRenderRoot() {
    return this;
  }

  updated() {
    const imageEditorEl = this.querySelector('[js-image-editor]');
    if (imageEditorEl && this.kaleidoId && this.kaleidoId !== 'undefined') {
      kaleido.editor(imageEditorEl, {
        id: this.kaleidoId,
        format: this.format,
        params: this.params
      });
    }
  }

  render() {
    console.log('RENDER IMAGE EDITOR');
    return html` <div class="" js-image-editor></div> `;
  }
}
// Define if not already defined
if (!window.customElements.get('pin-image-editor')) {
  window.customElements.define('pin-image-editor', ImageEditor);
}
