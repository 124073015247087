var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BaseElement from '../components/base/base-element';
import { html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import api from '../api/';
import Masthead from '../components/masthead/masthead';
import Banner from '../components/banner/banner';
import { orgPlayback, orgChevronOpen, orgWarn } from '@nrk/origo';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { on, off, trigger } from '../utils/events';
import { localStorage } from '../utils/storage';
import { ERROR_DIALOG, DRAG_AND_DROP_REORDER, PARTICIPANTS_SAVE } from '../events';
import LoaderInline from '../components/loader-inline';
import { getServerErrorContent } from '../components/dialog/error-dialog';
import { captureException } from '@sentry/browser';
import { checkResetFavorite, resetChecklistStorage } from '../components/start-round-checklist/checklistutils';
const { get, set } = localStorage;
let ParticipantsPage = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantsPage extends BaseElement {
    constructor() {
        super();
        this.participants = [];
        this.pauseTime = '';
        this.pauseMessage = '';
        this.willPause = false;
        this.isPaused = false;
        this.round = null;
        this.updatedTimestamp = null;
        this.timer = null;
        this.failedToUpdate = false;
        this.isLoading = false;
        this.isResettingFavorites = false;
        this.isChangingRoundState = false;
        this.isSavingParticipants = false;
        this.hasChange = false;
        this.savedFavorites = this.getFavoritesFromSessionStorage();
        this.onClick = this.onClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.pause = this.pause.bind(this);
        this.unpause = this.unpause.bind(this);
        this.setPauseTime = this.setPauseTime.bind(this);
        this.onReorder = this.onReorder.bind(this);
        this.saveParticipants = this.saveParticipants.bind(this);
        this.deleteFavorites = this.deleteFavorites.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        this.fetch();
        on(PARTICIPANTS_SAVE, this.saveParticipants);
        on(DRAG_AND_DROP_REORDER, this.onReorder);
        this.addEventListener('click', this.onClick);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        off(PARTICIPANTS_SAVE, this.saveParticipants);
        off(DRAG_AND_DROP_REORDER, this.onReorder);
        this.removeEventListener('click', this.onClick);
    }
    saveFavoritesToSessionStorage(participants) {
        const favoritesJSON = JSON.stringify(participants);
        sessionStorage.setItem('savedFavorites', favoritesJSON);
    }
    getFavoritesFromSessionStorage() {
        const favoritesJSON = sessionStorage.getItem('savedFavorites');
        if (favoritesJSON) {
            return JSON.parse(favoritesJSON);
        }
        else {
            return null;
        }
    }
    clearFavoritesFromSessionStorage() {
        sessionStorage.removeItem('savedFavorites');
    }
    sortParticipantsOnFewestFavorites(participants) {
        const participantsCopy = [...participants.filter((p) => p.favoriteCount > 0)];
        return participantsCopy.sort((a, b) => {
            return a.favoriteCount > b.favoriteCount ? 1 : -1;
        });
    }
    fetch() {
        if (this.participants.length === 0) {
            const participantsCache = get('participants') || {};
            this.participants = participantsCache.list || [];
            this.updatedTimestamp = participantsCache.updatedTimestamp || null;
        }
        this.isLoading = true;
        api.participants
            .getAll()
            .then((participants) => {
            this.failedToUpdate = false;
            this.participants = participants;
            this.updatedTimestamp = Date.now();
            set('participants', {
                list: this.participants,
                updatedTimestamp: this.updatedTimestamp
            });
            this.isLoading = false;
            this.requestUpdate();
        })
            .catch((err) => {
            console.log(err);
            this.failedToUpdate = true;
            this.isLoading = false;
            trigger(ERROR_DIALOG, {
                showDialog: true,
                title: 'En feil har skjedd',
                content: getServerErrorContent()
            });
            this.requestUpdate();
        });
        api.rounds
            .getRound()
            .then((round) => {
            if (round.endTime === null) {
                this.round = null;
            }
            else {
                this.round = round;
                this.setIsPaused(round);
            }
            this.requestUpdate();
        })
            .catch((err) => {
            console.log(err);
            trigger(ERROR_DIALOG, {
                showDialog: true,
                title: 'En feil har skjedd',
                content: getServerErrorContent()
            });
        });
    }
    setIsPaused(round) {
        const now = Date.now();
        const diff = new Date(round.endTime).getTime() - now;
        if (round.endTime && diff <= 0) {
            this.isPaused = true;
        }
        else if (round.endTime) {
            if (this.timer !== null) {
                clearTimeout(this.timer);
            }
            this.timer = window.setTimeout(() => {
                this.isPaused = true;
            }, diff);
        }
        else {
            this.isPaused = false;
        }
    }
    async saveParticipants() {
        this.hasChange = false;
        this.isSavingParticipants = true;
        try {
            await api.participants.saveParticipantsOrder(this.participants);
            await this.fetch();
        }
        catch (error) {
            captureException(error);
        }
        finally {
            this.isSavingParticipants = false;
        }
    }
    onReorder({ from, to }) {
        const fixedTo = from < to ? to - 1 : to;
        const list = [...this.participants.slice(0, from), ...this.participants.slice(from + 1)];
        this.participants = [
            ...list.slice(0, fixedTo),
            this.participants[from],
            ...list.slice(fixedTo)
        ];
        this.hasChange = true;
        this.requestUpdate();
    }
    onClick(e) {
        const target = e.target;
        if (target.closest('button[disabled]')) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        if (target.hasAttribute('js-close-dropdown')) {
            const dropdown = target.closest('pin-dropdown');
            if (dropdown) {
                dropdown.setAttribute('hidden', '');
            }
        }
    }
    deleteFavorites(e) {
        e.preventDefault();
        this.savedFavorites = this.sortParticipantsOnFewestFavorites(this.participants);
        this.saveFavoritesToSessionStorage(this.savedFavorites);
        this.isResettingFavorites = true;
        const dropdown = e.target.closest('pin-dropdown');
        dropdown.setAttribute('hidden', '');
        api.favorites.deleteFavorites().then(() => {
            this.isResettingFavorites = false;
            this.fetch();
        });
        checkResetFavorite();
        this.requestUpdate();
    }
    pause() {
        this.isChangingRoundState = true;
        this.requestUpdate();
        api.rounds
            .pause({
            time: this.pauseTime
            // message: this.pauseMessage
        })
            .then((result) => {
            const roundStatus = this.querySelector('pin-round-status');
            roundStatus.fetch();
            // reset
            this.pauseTime = 'now';
            const dropdown = this.querySelector('[js-dropdown-pause]');
            if (dropdown) {
                dropdown.setAttribute('hidden', '');
            }
            this.round = result;
            this.setIsPaused(result);
            this.isChangingRoundState = false;
            this.requestUpdate();
        })
            .catch((err) => {
            console.log(err);
            trigger(ERROR_DIALOG, {
                showDialog: true,
                title: 'En feil har skjedd',
                content: getServerErrorContent()
            });
        });
    }
    unpause() {
        this.clearFavoritesFromSessionStorage();
        this.savedFavorites = null;
        this.isChangingRoundState = true;
        this.requestUpdate();
        api.rounds.unpause().then(() => {
            this.round = null;
            this.isPaused = false;
            const roundStatus = this.querySelector('pin-round-status');
            this.isChangingRoundState = false;
            roundStatus.fetch();
            this.requestUpdate();
        });
        resetChecklistStorage();
    }
    setPauseTime(e) {
        if (e.target.value === 'now') {
            this.pauseTime = e.target.value;
        }
        else {
            this.pauseTime = Number(e.target.value);
        }
    }
    render() {
        return html `
      ${Masthead()}
      ${Banner(html `
          <div class="flex flex-row items-center mb-4">
            <h2 class="pin-text-header-1 mr-4">Artister</h2>
            <pin-round-status></pin-round-status>
          </div>
          <span class="pin-text-ingress">
            Velg artisten du ønsker å se detaljer på eller forslag fra publikum
          </span>
        `, this.isPaused ? 'bg-yellow-300' : 'bg-green-500')}

      <div class="block container mx-auto px-4 max-w-8xl">
        <div class="mb-10 lg:flex lg:flex-row-reverse lg:items-start">
          <div class="py-5 lg:pt-10 lg:w-1/4">
            <div class="lg:pl-10 mt-20">
              ${this.failedToUpdate && this.updatedTimestamp
            ? html `
                    <div class="flex items-center p-2 bg-yellow-400 text-xs mb-2">
                      ${unsafeHTML(orgWarn)} Sist oppdatert
                      ${new Date(this.updatedTimestamp).toLocaleString()}
                    </div>
                  `
            : ''}
              ${!this.failedToUpdate && this.updatedTimestamp
            ? html `
                    <div class="text-xs mb-2">
                      Sist oppdatert ${new Date(this.updatedTimestamp).toLocaleString()}
                    </div>
                  `
            : ''}
              <button
                class="flex w-full justify-center items-center pin-button pin-button--secondary"
                @click="${this.fetch}"
                ?disabled="${this.isLoading}"
              >
                ${this.isLoading
            ? html `
                      ${LoaderInline()}
                      <span class="block ml-2">Oppdaterer liste...</span>
                    `
            : html `
                      ${unsafeHTML(orgPlayback)}
                      <span class="block ml-2">Oppdater liste</span>
                    `}
              </button>
              <button
                class="flex w-full justify-center items-center mt-4 pin-button pin-button--secondary"
                ?disabled="${!this.isPaused || this.isResettingFavorites}"
                title="${this.isPaused
            ? ''
            : 'Kan bare nullstille favoritter når avstemningen er satt på pause'}"
              >
                ${this.isResettingFavorites
            ? html `
                      ${LoaderInline()}
                      <span class="block">Nullstiller ...</span>
                    `
            : html `
                      <span class="block">Nullstill favoritter</span>
                      ${unsafeHTML(orgChevronOpen)}
                    `}
              </button>
              <pin-dropdown class="pin-dropdown" js-delete-favorites hidden>
                <div>
                  Er du sikker på at du vil nullstille favoritter? Du kan ikke gå tilbake på dette
                  valget.
                </div>
                <div class="mt-8 flex flex-row">
                  <button class="pin-button pin-button--transparent mr-2" js-close-dropdown>
                    Avbryt
                  </button>
                  <button
                    class="pin-button pin-button--critical flex flex-row items-center"
                    @click="${this.deleteFavorites}"
                    ?disabled="${!this.isPaused || this.isResettingFavorites}"
                  >
                    ${this.isResettingFavorites
            ? html `
                          ${LoaderInline()}
                          <span class="block ml-2">Nullstiller ...</span>
                        `
            : html ` <span class="block">Nullstill favoritter</span> `}
                  </button>
                </div>
              </pin-dropdown>

              ${this.round
            ? html `
                    <button
                      class="flex w-full justify-center items-center mt-4 pin-button pin-button--success"
                      ?disabled="${this.isChangingRoundState}"
                    >
                      ${this.isChangingRoundState
                ? html `
                            ${LoaderInline()}
                            <span class="block ml-2">Starter avstemning...</span>
                          `
                : html ` <span class="block">Start avstemningen</span> `}
                    </button>
                    <pin-dropdown class="pin-dropdown" js-dropdown-pause hidden>
                      Starter du avstemningen vil brukere ha mulighet til å favorittmerke og gjette
                      på deltagere.
                      <div class="mt-8 flex flex-row">
                        <button class="pin-button pin-button--transparent mr-2" js-close-dropdown>
                          Avbryt
                        </button>
                        <button
                          class="pin-button pin-button--success"
                          @click="${this.unpause}"
                          ?disabled="${this.isChangingRoundState}"
                        >
                          ${this.isChangingRoundState
                ? html `
                                ${LoaderInline()}
                                <span class="block ml-2">Starter avstemning...</span>
                              `
                : html ` <span class="block">Start avstemningen</span> `}
                        </button>
                      </div>
                    </pin-dropdown>
                    <pin-start-round-checklist></pin-start-round-checklist>
                  `
            : html `
                    <button
                      class="flex w-full justify-center items-center mt-4 pin-button pin-button--pause"
                      ?disabled="${this.isChangingRoundState}"
                    >
                      ${this.isChangingRoundState
                ? html `
                            ${LoaderInline()}
                            <span class="block ml-2">Pauser avstemning...</span>
                          `
                : html ` Sett avstemning på pause ${unsafeHTML(orgChevronOpen)} `}
                    </button>
                    <pin-dropdown class="pin-dropdown" js-dropdown-pause hidden>
                      <label>
                        <span class="pin-text-label font-semibold">Velg et tidspunkt</span>
                        <select
                          class="pin-select py-2 px-3 border border-gray-300 bg-gray-100 rounded w-full"
                          @change="${this.setPauseTime}"
                        >
                          <option value="now">Nå</option>
                          <option value="1">Om ett minutt</option>
                          <option value="2">Om to minutter</option>
                          <option value="3">Om tre minutter</option>
                          <option value="4">Om fire minutter</option>
                          <option value="5">Om fem minutter</option>
                        </select>
                      </label>

                      <div class="mt-8 flex flex-row">
                        <button class="pin-button pin-button--transparent mr-2" js-close-dropdown>
                          Avbryt
                        </button>
                        <button
                          class="pin-button pin-button--pause"
                          @click="${this.pause}"
                          ?disabled="${this.isChangingRoundState}"
                        >
                          ${this.isChangingRoundState
                ? html `
                                ${LoaderInline()}
                                <span class="block ml-2">Pauser avstemning...</span>
                              `
                : html ` <span class="block">Pause avstemningen</span> `}
                        </button>
                      </div>
                    </pin-dropdown>
                  `}
              ${this.savedFavorites && this.savedFavorites.length > 0
            ? html `<div><strong>Navn - Favoritter</strong></div>
                    <ul>
                      ${this.savedFavorites.map((p) => html `<li>${p.maskName} - ${p.favoriteCount}</li>`)}
                    </ul>`
            : ''}
            </div>
          </div>
          <div class="mb-10 lg:pt-10 lg:w-3/4">
            <pin-participants-list
              .participants="${this.participants}"
              .isSavingParticipants="${this.isSavingParticipants}"
              .hasChange="${this.hasChange}"
            ></pin-participants-list>
          </div>
        </div>
      </div>
    `;
    }
};
__decorate([
    state()
], ParticipantsPage.prototype, "participants", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "pauseTime", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "pauseMessage", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "willPause", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "isPaused", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "round", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "updatedTimestamp", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "timer", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "failedToUpdate", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "isLoading", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "isResettingFavorites", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "isChangingRoundState", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "isSavingParticipants", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "hasChange", void 0);
__decorate([
    state()
], ParticipantsPage.prototype, "savedFavorites", void 0);
ParticipantsPage = __decorate([
    customElement('pin-participants-page')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantsPage);
