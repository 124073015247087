export function StorageFactory(type, _prefix = null) {
    let store = window.localStorage;
    const prefix = _prefix;
    if (type === 'session') {
        store = window.sessionStorage;
    }
    function getKey(collectionId) {
        if (prefix === null) {
            return collectionId;
        }
        else {
            return String(prefix + '-' + collectionId);
        }
    }
    return {
        set(collectionId, items) {
            store.setItem(getKey(String(collectionId)), JSON.stringify(items));
        },
        remove(collectionId) {
            store.removeItem(getKey(String(collectionId)));
        },
        get(collectionId) {
            const fromStore = store.getItem(getKey(collectionId));
            return fromStore ? JSON.parse(fromStore) : null;
        }
    };
}
export const localStorage = StorageFactory('local', 'maskorama-admin');
export const sessionStorage = StorageFactory('session', 'maskorama-admin');
